import type { ComponentProps, VFC } from "react";
import { BaseButton } from "src/components/ui/buttons/BaseButton";

type Props = ComponentProps<typeof BaseButton>;

export const PrimaryButton: VFC<Props> = (props) => {
  const { children, ...styles } = props;
  return (
    <BaseButton {...styles} bg="primary.main" color="white">
      {children}
    </BaseButton>
  );
};
