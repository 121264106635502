import type { TextProps } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import Link from "next/link";
import type { ReactNode, VFC } from "react";

type BaseProps = {
  children: ReactNode;
  textProps?: TextProps;
};
type LinkProps = BaseProps & { to: string };
type ClickProps = BaseProps & { onClick: () => void };

type Props = LinkProps | ClickProps;

const isLink = (props: Props): props is LinkProps => "to" in props;

const styles: TextProps = {
  color: "link",
  cursor: "pointer",
  borderBottom: "1px",
  borderColor: "link",
  as: "span",
};

export const LinkText: VFC<Props> = (props) => {
  const { children, textProps } = props;
  return isLink(props) ? (
    <Link href={props.to}>
      <a>
        <Text {...styles} {...textProps}>
          {children}
        </Text>
      </a>
    </Link>
  ) : (
    <Text {...styles} {...textProps} onClick={props.onClick}>
      {children}
    </Text>
  );
};
