import { useCallback, useMemo } from "react";
import { Mixpanel } from "src/libs/mixpanel";
import { useUserStore } from "src/store/useUserStore";

export const useMixpanel = () => {
  const { user } = useUserStore();

  const track: typeof Mixpanel.track = useCallback(
    (eventName, properties) => {
      if (!user) return;
      Mixpanel.identify(user.id);
      Mixpanel.track(eventName, properties);
    },
    [user]
  );

  const mixpanel = useMemo(
    () => ({
      track,
    }),
    [track]
  );

  return { mixpanel };
};
