import * as Sentry from "@sentry/nextjs";
import gql from "graphql-tag";
import moji from "moji";
import { useCallback, useState } from "react";
import type {
  GetOrderInfoQuery,
  GetOrderInfoQueryVariables,
  ListOrderInfoBySpecificCustomerQuery,
  ListOrderInfoBySpecificCustomerQueryVariables,
  ListOrderInfoBySpecificCustomerWithStatusQuery,
  ListOrderInfoBySpecificCustomerWithStatusQueryVariables,
  ModelOrderInfoFilterInput,
  ModelStringKeyConditionInput,
} from "src/graphql/API";
import { ModelSortDirection, OrderStatus } from "src/graphql/API";
import {
  getOrderInfo,
  listOrderInfoBySpecificCustomer,
  listOrderInfoBySpecificCustomerWithStatus,
} from "src/graphql/queries";
import { getAppSyncClient } from "src/libs/appSyncClient";
import { Day } from "src/libs/day";
import type { GraphQLErrors, OrderCondition, OrderInfo, Response } from "src/types";
import { Status } from "src/types";

import { useMixpanelOrder } from "./useMixpanelOrder";

const DATA_RANGE_PREFIX = {
  START: "000000000",
  END: "999999999",
};

const LIMIT = 100;

export const useOrder = () => {
  const [orderInfoList, setOrderInfoList] = useState<OrderInfo[] | null>(null);
  const [isOrdersLoading, setIsOrdersLoading] = useState(false);
  const [isOrderLoading, setIsOrderLoading] = useState(false);
  const { trackOrderSearchCondition, trackOrderSearchById } = useMixpanelOrder();
  const [nextToken, setNextToken] = useState<string | undefined>(undefined);

  const getOrdersByCustomer = useCallback(
    async (condition: OrderCondition, nextToken?: string): Promise<Response<OrderInfo[]>> => {
      let orderedAtSlipSeq: ModelStringKeyConditionInput | null = null;
      let filter: ModelOrderInfoFilterInput | null = null;
      filter = {};
      let start: Day | null = null;
      let end: Day | null = null;

      switch (condition.orderDateRange) {
        case "MANUAL": {
          start = condition.startDate;
          end = condition.endDate;
          break;
        }
        case "ALL":
          break;
        default: {
          const range = Day.convertDateRange(condition.orderDateRange);
          start = range[0];
          end = range[1];
        }
      }
      if (start && end) {
        orderedAtSlipSeq = {
          between: [
            `${start.format("YYYYMMDD")}#${DATA_RANGE_PREFIX.START}`,
            `${end.format("YYYYMMDD")}#${DATA_RANGE_PREFIX.END}`,
          ],
        };
      }
      let and: ModelOrderInfoFilterInput[] | null = null;
      if (condition.place) {
        if (!and) and = [];
        and.push({
          remark1: { contains: condition.place },
        });
      }
      if (condition.productNo) {
        if (!and) and = [];
        and.push({
          keywordProductName: { contains: translate(condition.productNo) },
        });
      }
      if (condition.productName) {
        if (!and) and = [];
        and.push({
          keywordProductName: { contains: translate(condition.productName) },
        });
      }

      if (and !== null) {
        filter.and = and;
      }

      const variables: ListOrderInfoBySpecificCustomerQueryVariables = {
        customerCode: condition.customerCode,
        orderedAtSlipSeq,
        filter,
        sortDirection: ModelSortDirection.DESC,
        nextToken,
        limit: LIMIT,
      };
      const client = getAppSyncClient();
      const { data, errors } = await client
        .query<ListOrderInfoBySpecificCustomerQuery, ListOrderInfoBySpecificCustomerQueryVariables>({
          query: gql(listOrderInfoBySpecificCustomer),
          fetchPolicy: "network-only",
          variables,
        })
        .catch((errors: GraphQLErrors) => {
          return { data: null, errors };
        });

      if (errors) {
        Sentry.captureException(errors, {
          extra: {
            query: listOrderInfoBySpecificCustomer,
            variables,
          },
        });
        return { data: null, errors };
      }
      if (data === null) {
        return { data, errors };
      }

      const newOrderInfoList =
        data.listOrderInfoBySpecificCustomer?.items
          .filter((item): item is NonNullable<typeof item> => !!item)
          .map<OrderInfo>(({ products, ...order }) => {
            const newOrderInfo: OrderInfo = {
              order,
              products: products?.items.filter((item): item is NonNullable<typeof item> => !!item) ?? [],
            };
            return newOrderInfo;
          }) ?? [];

      if (nextToken) {
        setOrderInfoList((prev) => [...(prev ?? []), ...newOrderInfoList]);
      } else {
        setOrderInfoList(newOrderInfoList);
      }
      setNextToken(data.listOrderInfoBySpecificCustomer?.nextToken ?? undefined);

      return { data: newOrderInfoList, errors: null };
    },
    []
  );

  const getOrdersByCustomerWithStatus = useCallback(
    async (condition: OrderCondition, nextToken?: string): Promise<Response<OrderInfo[]>> => {
      if (condition.status === Status.ALL) {
        throw new Error("can not set status 'ALL'");
      }
      let statusOrderedAtSlipSeq: ModelStringKeyConditionInput | null = null;
      const filter: ModelOrderInfoFilterInput = {};

      const orderStatus = condition.status === Status.COMPLETED ? OrderStatus.COMPLETED : OrderStatus.IN_PROGRESS;
      let start: Day | null = null;
      let end: Day | null = null;

      switch (condition.orderDateRange) {
        case "MANUAL": {
          start = condition.startDate;
          end = condition.endDate;
          break;
        }
        case "ALL":
          break;
        default: {
          const range = Day.convertDateRange(condition.orderDateRange);
          start = range[0];
          end = range[1];
        }
      }
      if (start && end) {
        statusOrderedAtSlipSeq = {
          between: [
            `${orderStatus}#${start.format("YYYYMMDD")}#${DATA_RANGE_PREFIX.START}`,
            `${orderStatus}#${end.format("YYYYMMDD")}#${DATA_RANGE_PREFIX.END}`,
          ],
        };
      } else {
        statusOrderedAtSlipSeq = { beginsWith: `${orderStatus}#` };
      }

      let and: ModelOrderInfoFilterInput[] | null = null;
      if (condition.place) {
        if (!and) and = [];
        and.push({
          remark1: { contains: condition.place },
        });
      }
      if (condition.productNo) {
        if (!and) and = [];
        and.push({
          keywordProductName: { contains: translate(condition.productNo) },
        });
      }
      if (condition.productName) {
        if (!and) and = [];
        and.push({
          keywordProductName: { contains: translate(condition.productName) },
        });
      }

      if (and !== null) {
        filter.and = and;
      }

      const variables: ListOrderInfoBySpecificCustomerWithStatusQueryVariables = {
        customerCode: condition.customerCode,
        statusOrderedAtSlipSeq,
        filter,
        sortDirection: ModelSortDirection.DESC,
        nextToken,
        limit: LIMIT,
      };
      const client = getAppSyncClient();
      const { data, errors } = await client
        .query<ListOrderInfoBySpecificCustomerWithStatusQuery, ListOrderInfoBySpecificCustomerWithStatusQueryVariables>(
          {
            query: gql(listOrderInfoBySpecificCustomerWithStatus),
            fetchPolicy: "network-only",
            variables,
          }
        )
        .catch((errors: GraphQLErrors) => {
          return { data: null, errors };
        });

      if (errors) {
        Sentry.captureException(errors, {
          extra: {
            query: listOrderInfoBySpecificCustomerWithStatus,
            variables,
          },
        });
        return { data: null, errors };
      }
      if (data === null) {
        return { data, errors };
      }

      const newOrderInfoList =
        data.listOrderInfoBySpecificCustomerWithStatus?.items
          .filter((item): item is NonNullable<typeof item> => !!item)
          .map<OrderInfo>(({ products, ...order }) => {
            const newOrderInfo: OrderInfo = {
              order,
              products: products?.items.filter((item): item is NonNullable<typeof item> => !!item) ?? [],
            };
            return newOrderInfo;
          }) ?? [];

      if (nextToken) {
        setOrderInfoList((prev) => [...(prev ?? []), ...newOrderInfoList]);
      } else {
        setOrderInfoList(newOrderInfoList);
      }
      setNextToken(data.listOrderInfoBySpecificCustomerWithStatus?.nextToken ?? undefined);

      return { data: newOrderInfoList, errors: null };
    },
    []
  );

  const getOrders = useCallback(
    async (condition: OrderCondition): Promise<Response<OrderInfo[]>> => {
      if (!condition.customerCode) {
        return { data: null, errors: null };
      }
      try {
        setIsOrdersLoading(true);
        trackOrderSearchCondition(condition);
        if (condition.status === Status.ALL) {
          return await getOrdersByCustomer(condition);
        } else {
          return await getOrdersByCustomerWithStatus(condition);
        }
      } finally {
        setIsOrdersLoading(false);
      }
    },
    [getOrdersByCustomer, getOrdersByCustomerWithStatus, trackOrderSearchCondition]
  );

  const getNextOrders = useCallback(
    async (condition: OrderCondition): Promise<Response<OrderInfo[]>> => {
      if (!condition.customerCode) {
        return { data: null, errors: null };
      }
      if (!nextToken) {
        return { data: null, errors: null };
      }
      if (condition.status === Status.ALL) {
        return await getOrdersByCustomer(condition, nextToken);
      } else {
        return await getOrdersByCustomerWithStatus(condition, nextToken);
      }
    },
    [getOrdersByCustomer, getOrdersByCustomerWithStatus, trackOrderSearchCondition, nextToken]
  );

  const getOrderById = useCallback(
    async (orderNo: string): Promise<Response<OrderInfo>> => {
      try {
        setIsOrderLoading(true);

        const variables: GetOrderInfoQueryVariables = { orderNo };
        const client = getAppSyncClient();
        const { data, errors } = await client
          .query<GetOrderInfoQuery, GetOrderInfoQueryVariables>({
            query: gql(getOrderInfo),
            fetchPolicy: "network-only",
            variables,
          })
          .catch((errors: GraphQLErrors) => {
            return {
              data: null,
              errors,
            };
          });

        if (errors) {
          Sentry.captureException(errors, {
            extra: {
              query: getOrderInfo,
              variables,
            },
          });
          return { data: null, errors };
        }
        if (data === null) {
          return { data, errors };
        }

        if (!data.getOrderInfo) {
          return { data: null, errors: null };
        }
        const { products, ...order } = data.getOrderInfo;
        const newOrderInfo: OrderInfo = {
          order,
          products: products?.items.filter((item): item is NonNullable<typeof item> => !!item) ?? [],
        };

        trackOrderSearchById(orderNo);

        return { data: newOrderInfo, errors: null };
      } finally {
        setIsOrderLoading(false);
      }
    },
    [trackOrderSearchById]
  );

  // TODO: 本番環境でエラー通知の検証が完了したら削除する
  const doBadRequest = useCallback(async () => {
    const getOrderInfo = /* GraphQL */ `
      query GetOrderInfo($orderNo: ID!) {
        getOrderInfo__Dummy__(orderNo: $orderNo) {
          orderNo
          products {
            items {
              productId
              orderNo
              order {
                orderNo
                slipSeq
                customerCode
                customerName
                remark1
                remark2
                orderStatus
                orderedAt
                orderedAtSlipSeq
                statusOrderedAtSlipSeq
                keywordProductName
                updatedAt
              }
            }
          }
        }
      }
    `;
    const variables: GetOrderInfoQueryVariables = { orderNo: "0" };
    const client = getAppSyncClient();
    const { data, errors } = await client
      .query<GetOrderInfoQuery, GetOrderInfoQueryVariables>({
        query: gql(getOrderInfo),
        fetchPolicy: "network-only",
        variables,
      })
      .catch((errors: GraphQLErrors) => {
        return {
          data: null,
          errors,
        };
      });

    if (errors != null) {
      console.log("send");

      Sentry.captureException(errors, {
        extra: {
          query: getOrderInfo,
          variables,
        },
      });
      return { data: null, errors };
    }
    return { data, errors: null };
  }, []);

  return {
    orderInfoList,
    getOrders,
    getNextOrders,
    getOrderById,
    doBadRequest,
    hasMoreOrders: !!nextToken,
    isLoading: { isOrdersLoading, isOrderLoading },
  };
};

const translate = (text: string): string => {
  return moji(text).convert("ZE", "HE").convert("ZS", "HS").convert("ZK", "HK").toString().toUpperCase();
};
