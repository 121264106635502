/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateOrderInfoInput = {
  orderNo: string,
  slipSeq: string,
  customerCode: string,
  customerName: string,
  remark1?: string | null,
  remark2?: string | null,
  orderStatus: OrderStatus,
  orderedAt: string,
  orderedAtSlipSeq: string,
  statusOrderedAtSlipSeq: string,
  keywordProductName?: string | null,
  updatedAt?: string | null,
};

// 注文ステータス
export enum OrderStatus {
  IN_PROGRESS = "IN_PROGRESS", // 進行中
  COMPLETED = "COMPLETED", // 完了
  CANCELED = "CANCELED", // キャンセル
}


export type ModelOrderInfoConditionInput = {
  slipSeq?: ModelStringInput | null,
  customerCode?: ModelStringInput | null,
  customerName?: ModelStringInput | null,
  remark1?: ModelStringInput | null,
  remark2?: ModelStringInput | null,
  orderStatus?: ModelOrderStatusInput | null,
  orderedAt?: ModelStringInput | null,
  orderedAtSlipSeq?: ModelStringInput | null,
  statusOrderedAtSlipSeq?: ModelStringInput | null,
  keywordProductName?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOrderInfoConditionInput | null > | null,
  or?: Array< ModelOrderInfoConditionInput | null > | null,
  not?: ModelOrderInfoConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelOrderStatusInput = {
  eq?: OrderStatus | null,
  ne?: OrderStatus | null,
};

export type OrderInfo = {
  __typename: "OrderInfo",
  // 注文番号(受注NO)
  orderNo: string,
  products?: ModelProductInfoConnection | null,
  // 伝票SEQ
  slipSeq: string,
  // 得意先コード
  customerCode: string,
  // 得意先名称
  customerName: string,
  // 摘要1
  remark1?: string | null,
  // 摘要2
  remark2?: string | null,
  // 注文ステータス
  orderStatus: OrderStatus,
  // 注文日
  orderedAt: string,
  // 注文日_伝票SEQ
  orderedAtSlipSeq: string,
  // 注文ステータス_注文日＿伝票SEQ
  statusOrderedAtSlipSeq: string,
  // 注文に紐づく商品名の結合文字列（品名での絞り込み検索に使用）
  // 後から品番の文字列も結合（属性名を適切な命名にしたい）
  keywordProductName?: string | null,
  // DWHからデータ連携した日時
  updatedAt: string,
};

export type ModelProductInfoConnection = {
  __typename: "ModelProductInfoConnection",
  items:  Array<ProductInfo | null >,
  nextToken?: string | null,
};

export type ProductInfo = {
  __typename: "ProductInfo",
  // 商品ID
  productId: string,
  // 注文ID（外部キー）
  orderNo: string,
  // 注文情報
  order: OrderInfo,
  // 得意先コード
  customerCode: string,
  // 得意先名称
  customerName: string,
  // 品番
  productCode: string,
  // 品名
  productName?: string | null,
  // 個数
  quantity: number,
  // 定価
  listPrice: number,
  // 単価
  unitPrice: number,
  // 金額(単価×個数)
  amount: number,
  // 注文日
  orderedAt: string,
  // 手配日
  arrangedAt?: string | null,
  // 納品予定日
  deliveryDate?: string | null,
  // 伝票日付
  slipDate?: string | null,
  // 商品ステータス
  productStatus: ProductStatus,
  // DWHからデータ連携した日時
  updatedAt: string,
};

// 商品ステータス
export enum ProductStatus {
  COMPLETED = "COMPLETED", // 発送済み
  CANCELED = "CANCELED", // キャンセル
  ARRANGED = "ARRANGED", // 手配済み
  SCHEDULED_DELIVERY = "SCHEDULED_DELIVERY", // 納品予定
  IN_PROGRESS = "IN_PROGRESS", // 注文済み
}


export type UpdateOrderInfoInput = {
  orderNo: string,
  slipSeq?: string | null,
  customerCode?: string | null,
  customerName?: string | null,
  remark1?: string | null,
  remark2?: string | null,
  orderStatus?: OrderStatus | null,
  orderedAt?: string | null,
  orderedAtSlipSeq?: string | null,
  statusOrderedAtSlipSeq?: string | null,
  keywordProductName?: string | null,
  updatedAt?: string | null,
};

export type DeleteOrderInfoInput = {
  orderNo: string,
};

export type CreateProductInfoInput = {
  productId: string,
  orderNo: string,
  customerCode: string,
  customerName: string,
  productCode: string,
  productName?: string | null,
  quantity: number,
  listPrice: number,
  unitPrice: number,
  amount: number,
  orderedAt: string,
  arrangedAt?: string | null,
  deliveryDate?: string | null,
  slipDate?: string | null,
  productStatus: ProductStatus,
  updatedAt?: string | null,
};

export type ModelProductInfoConditionInput = {
  orderNo?: ModelIDInput | null,
  customerCode?: ModelStringInput | null,
  customerName?: ModelStringInput | null,
  productCode?: ModelStringInput | null,
  productName?: ModelStringInput | null,
  quantity?: ModelIntInput | null,
  listPrice?: ModelFloatInput | null,
  unitPrice?: ModelFloatInput | null,
  amount?: ModelFloatInput | null,
  orderedAt?: ModelStringInput | null,
  arrangedAt?: ModelStringInput | null,
  deliveryDate?: ModelStringInput | null,
  slipDate?: ModelStringInput | null,
  productStatus?: ModelProductStatusInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelProductInfoConditionInput | null > | null,
  or?: Array< ModelProductInfoConditionInput | null > | null,
  not?: ModelProductInfoConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelProductStatusInput = {
  eq?: ProductStatus | null,
  ne?: ProductStatus | null,
};

export type UpdateProductInfoInput = {
  productId: string,
  orderNo?: string | null,
  customerCode?: string | null,
  customerName?: string | null,
  productCode?: string | null,
  productName?: string | null,
  quantity?: number | null,
  listPrice?: number | null,
  unitPrice?: number | null,
  amount?: number | null,
  orderedAt?: string | null,
  arrangedAt?: string | null,
  deliveryDate?: string | null,
  slipDate?: string | null,
  productStatus?: ProductStatus | null,
  updatedAt?: string | null,
};

export type DeleteProductInfoInput = {
  productId: string,
};

export type CreateUserInput = {
  id?: string | null,
  name: string,
  displayName: string,
  role: Role,
  customerCode?: string | null,
  salesOfficeCode?: string | null,
  isAdmin?: boolean | null,
};

export enum Role {
  NONE = "NONE",
  EMPLOYEE = "EMPLOYEE",
  CUSTOMER = "CUSTOMER",
}


export type ModelUserConditionInput = {
  name?: ModelStringInput | null,
  displayName?: ModelStringInput | null,
  role?: ModelRoleInput | null,
  customerCode?: ModelIDInput | null,
  salesOfficeCode?: ModelIDInput | null,
  isAdmin?: ModelBooleanInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelRoleInput = {
  eq?: Role | null,
  ne?: Role | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type User = {
  __typename: "User",
  // CognitoのID
  id: string,
  // Cognitoのユーザー名
  name: string,
  // アプリの表示名
  displayName: string,
  // ロール（小川管理者・小川従業員・顧客）
  role: Role,
  // 得意先コード（外部キー）
  customerCode?: string | null,
  // 得意先ユーザーの所属企業
  customer?: Customer | null,
  // 小川営業所コード（外部キー）
  salesOfficeCode?: string | null,
  // 小川従業員の所属営業所
  salesOffice?: SalesOffice | null,
  // 管理者フラグ
  isAdmin?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type Customer = {
  __typename: "Customer",
  code: string,
  name: string,
  salesOfficeCode?: string | null,
  salesOffice?: SalesOffice | null,
  employee?: ModelUserConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type SalesOffice = {
  __typename: "SalesOffice",
  code: string,
  name: string,
  customers?: ModelCustomerConnection | null,
  employee?: ModelUserConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelCustomerConnection = {
  __typename: "ModelCustomerConnection",
  items:  Array<Customer | null >,
  nextToken?: string | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type UpdateUserInput = {
  id: string,
  name?: string | null,
  displayName?: string | null,
  role?: Role | null,
  customerCode?: string | null,
  salesOfficeCode?: string | null,
  isAdmin?: boolean | null,
};

export type DeleteUserInput = {
  id: string,
};

export type CreateSalesOfficeInput = {
  code: string,
  name: string,
};

export type ModelSalesOfficeConditionInput = {
  name?: ModelStringInput | null,
  and?: Array< ModelSalesOfficeConditionInput | null > | null,
  or?: Array< ModelSalesOfficeConditionInput | null > | null,
  not?: ModelSalesOfficeConditionInput | null,
};

export type UpdateSalesOfficeInput = {
  code: string,
  name?: string | null,
};

export type DeleteSalesOfficeInput = {
  code: string,
};

export type CreateCustomerInput = {
  code: string,
  name: string,
  salesOfficeCode?: string | null,
};

export type ModelCustomerConditionInput = {
  name?: ModelStringInput | null,
  salesOfficeCode?: ModelIDInput | null,
  and?: Array< ModelCustomerConditionInput | null > | null,
  or?: Array< ModelCustomerConditionInput | null > | null,
  not?: ModelCustomerConditionInput | null,
};

export type UpdateCustomerInput = {
  code: string,
  name?: string | null,
  salesOfficeCode?: string | null,
};

export type DeleteCustomerInput = {
  code: string,
};

export type CreateSalesOfficeUserInput = {
  salesOfficeCode: string,
  initialPassword: string,
  users: Array< UserInput >,
};

export type UserInput = {
  email: string,
  name: string,
};

export type CreateCustomerUserInput = {
  salesOfficeCode: string,
  customerCode: string,
  initialPassword: string,
  users: Array< UserInput >,
};

export type GetEvaluateFeatureResponse = {
  __typename: "GetEvaluateFeatureResponse",
  details?: string | null,
  reason?: string | null,
  value?: EvaluateFeatureValue | null,
  variation?: string | null,
};

export type EvaluateFeatureValue = {
  __typename: "EvaluateFeatureValue",
  boolValue?: boolean | null,
  doubleValue?: number | null,
  longValue?: number | null,
  stringValue?: string | null,
};

export type ModelOrderInfoFilterInput = {
  orderNo?: ModelIDInput | null,
  slipSeq?: ModelStringInput | null,
  customerCode?: ModelStringInput | null,
  customerName?: ModelStringInput | null,
  remark1?: ModelStringInput | null,
  remark2?: ModelStringInput | null,
  orderStatus?: ModelOrderStatusInput | null,
  orderedAt?: ModelStringInput | null,
  orderedAtSlipSeq?: ModelStringInput | null,
  statusOrderedAtSlipSeq?: ModelStringInput | null,
  keywordProductName?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOrderInfoFilterInput | null > | null,
  or?: Array< ModelOrderInfoFilterInput | null > | null,
  not?: ModelOrderInfoFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelOrderInfoConnection = {
  __typename: "ModelOrderInfoConnection",
  items:  Array<OrderInfo | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelProductInfoFilterInput = {
  productId?: ModelIDInput | null,
  orderNo?: ModelIDInput | null,
  customerCode?: ModelStringInput | null,
  customerName?: ModelStringInput | null,
  productCode?: ModelStringInput | null,
  productName?: ModelStringInput | null,
  quantity?: ModelIntInput | null,
  listPrice?: ModelFloatInput | null,
  unitPrice?: ModelFloatInput | null,
  amount?: ModelFloatInput | null,
  orderedAt?: ModelStringInput | null,
  arrangedAt?: ModelStringInput | null,
  deliveryDate?: ModelStringInput | null,
  slipDate?: ModelStringInput | null,
  productStatus?: ModelProductStatusInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelProductInfoFilterInput | null > | null,
  or?: Array< ModelProductInfoFilterInput | null > | null,
  not?: ModelProductInfoFilterInput | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  displayName?: ModelStringInput | null,
  role?: ModelRoleInput | null,
  customerCode?: ModelIDInput | null,
  salesOfficeCode?: ModelIDInput | null,
  isAdmin?: ModelBooleanInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelSalesOfficeFilterInput = {
  code?: ModelIDInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelSalesOfficeFilterInput | null > | null,
  or?: Array< ModelSalesOfficeFilterInput | null > | null,
  not?: ModelSalesOfficeFilterInput | null,
};

export type ModelSalesOfficeConnection = {
  __typename: "ModelSalesOfficeConnection",
  items:  Array<SalesOffice | null >,
  nextToken?: string | null,
};

export type ModelCustomerFilterInput = {
  code?: ModelIDInput | null,
  name?: ModelStringInput | null,
  salesOfficeCode?: ModelIDInput | null,
  and?: Array< ModelCustomerFilterInput | null > | null,
  or?: Array< ModelCustomerFilterInput | null > | null,
  not?: ModelCustomerFilterInput | null,
};

export type ModelSubscriptionOrderInfoFilterInput = {
  orderNo?: ModelSubscriptionIDInput | null,
  slipSeq?: ModelSubscriptionStringInput | null,
  customerCode?: ModelSubscriptionStringInput | null,
  customerName?: ModelSubscriptionStringInput | null,
  remark1?: ModelSubscriptionStringInput | null,
  remark2?: ModelSubscriptionStringInput | null,
  orderStatus?: ModelSubscriptionStringInput | null,
  orderedAt?: ModelSubscriptionStringInput | null,
  orderedAtSlipSeq?: ModelSubscriptionStringInput | null,
  statusOrderedAtSlipSeq?: ModelSubscriptionStringInput | null,
  keywordProductName?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOrderInfoFilterInput | null > | null,
  or?: Array< ModelSubscriptionOrderInfoFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionProductInfoFilterInput = {
  productId?: ModelSubscriptionIDInput | null,
  orderNo?: ModelSubscriptionIDInput | null,
  customerCode?: ModelSubscriptionStringInput | null,
  customerName?: ModelSubscriptionStringInput | null,
  productCode?: ModelSubscriptionStringInput | null,
  productName?: ModelSubscriptionStringInput | null,
  quantity?: ModelSubscriptionIntInput | null,
  listPrice?: ModelSubscriptionFloatInput | null,
  unitPrice?: ModelSubscriptionFloatInput | null,
  amount?: ModelSubscriptionFloatInput | null,
  orderedAt?: ModelSubscriptionStringInput | null,
  arrangedAt?: ModelSubscriptionStringInput | null,
  deliveryDate?: ModelSubscriptionStringInput | null,
  slipDate?: ModelSubscriptionStringInput | null,
  productStatus?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProductInfoFilterInput | null > | null,
  or?: Array< ModelSubscriptionProductInfoFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  displayName?: ModelSubscriptionStringInput | null,
  role?: ModelSubscriptionStringInput | null,
  customerCode?: ModelSubscriptionIDInput | null,
  salesOfficeCode?: ModelSubscriptionIDInput | null,
  isAdmin?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionSalesOfficeFilterInput = {
  code?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSalesOfficeFilterInput | null > | null,
  or?: Array< ModelSubscriptionSalesOfficeFilterInput | null > | null,
};

export type ModelSubscriptionCustomerFilterInput = {
  code?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  salesOfficeCode?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionCustomerFilterInput | null > | null,
  or?: Array< ModelSubscriptionCustomerFilterInput | null > | null,
};

export type CreateOrderInfoMutationVariables = {
  input: CreateOrderInfoInput,
  condition?: ModelOrderInfoConditionInput | null,
};

export type CreateOrderInfoMutation = {
  createOrderInfo?:  {
    __typename: "OrderInfo",
    // 注文番号(受注NO)
    orderNo: string,
    products?:  {
      __typename: "ModelProductInfoConnection",
      items:  Array< {
        __typename: "ProductInfo",
        // 商品ID
        productId: string,
        // 注文ID（外部キー）
        orderNo: string,
        // 注文情報
        order:  {
          __typename: "OrderInfo",
          // 注文番号(受注NO)
          orderNo: string,
          // 伝票SEQ
          slipSeq: string,
          // 得意先コード
          customerCode: string,
          // 得意先名称
          customerName: string,
          // 摘要1
          remark1?: string | null,
          // 摘要2
          remark2?: string | null,
          // 注文ステータス
          orderStatus: OrderStatus,
          // 注文日
          orderedAt: string,
          // 注文日_伝票SEQ
          orderedAtSlipSeq: string,
          // 注文ステータス_注文日＿伝票SEQ
          statusOrderedAtSlipSeq: string,
          // 注文に紐づく商品名の結合文字列（品名での絞り込み検索に使用）
          // 後から品番の文字列も結合（属性名を適切な命名にしたい）
          keywordProductName?: string | null,
          // DWHからデータ連携した日時
          updatedAt: string,
        },
        // 得意先コード
        customerCode: string,
        // 得意先名称
        customerName: string,
        // 品番
        productCode: string,
        // 品名
        productName?: string | null,
        // 個数
        quantity: number,
        // 定価
        listPrice: number,
        // 単価
        unitPrice: number,
        // 金額(単価×個数)
        amount: number,
        // 注文日
        orderedAt: string,
        // 手配日
        arrangedAt?: string | null,
        // 納品予定日
        deliveryDate?: string | null,
        // 伝票日付
        slipDate?: string | null,
        // 商品ステータス
        productStatus: ProductStatus,
        // DWHからデータ連携した日時
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    // 伝票SEQ
    slipSeq: string,
    // 得意先コード
    customerCode: string,
    // 得意先名称
    customerName: string,
    // 摘要1
    remark1?: string | null,
    // 摘要2
    remark2?: string | null,
    // 注文ステータス
    orderStatus: OrderStatus,
    // 注文日
    orderedAt: string,
    // 注文日_伝票SEQ
    orderedAtSlipSeq: string,
    // 注文ステータス_注文日＿伝票SEQ
    statusOrderedAtSlipSeq: string,
    // 注文に紐づく商品名の結合文字列（品名での絞り込み検索に使用）
    // 後から品番の文字列も結合（属性名を適切な命名にしたい）
    keywordProductName?: string | null,
    // DWHからデータ連携した日時
    updatedAt: string,
  } | null,
};

export type UpdateOrderInfoMutationVariables = {
  input: UpdateOrderInfoInput,
  condition?: ModelOrderInfoConditionInput | null,
};

export type UpdateOrderInfoMutation = {
  updateOrderInfo?:  {
    __typename: "OrderInfo",
    // 注文番号(受注NO)
    orderNo: string,
    products?:  {
      __typename: "ModelProductInfoConnection",
      items:  Array< {
        __typename: "ProductInfo",
        // 商品ID
        productId: string,
        // 注文ID（外部キー）
        orderNo: string,
        // 注文情報
        order:  {
          __typename: "OrderInfo",
          // 注文番号(受注NO)
          orderNo: string,
          // 伝票SEQ
          slipSeq: string,
          // 得意先コード
          customerCode: string,
          // 得意先名称
          customerName: string,
          // 摘要1
          remark1?: string | null,
          // 摘要2
          remark2?: string | null,
          // 注文ステータス
          orderStatus: OrderStatus,
          // 注文日
          orderedAt: string,
          // 注文日_伝票SEQ
          orderedAtSlipSeq: string,
          // 注文ステータス_注文日＿伝票SEQ
          statusOrderedAtSlipSeq: string,
          // 注文に紐づく商品名の結合文字列（品名での絞り込み検索に使用）
          // 後から品番の文字列も結合（属性名を適切な命名にしたい）
          keywordProductName?: string | null,
          // DWHからデータ連携した日時
          updatedAt: string,
        },
        // 得意先コード
        customerCode: string,
        // 得意先名称
        customerName: string,
        // 品番
        productCode: string,
        // 品名
        productName?: string | null,
        // 個数
        quantity: number,
        // 定価
        listPrice: number,
        // 単価
        unitPrice: number,
        // 金額(単価×個数)
        amount: number,
        // 注文日
        orderedAt: string,
        // 手配日
        arrangedAt?: string | null,
        // 納品予定日
        deliveryDate?: string | null,
        // 伝票日付
        slipDate?: string | null,
        // 商品ステータス
        productStatus: ProductStatus,
        // DWHからデータ連携した日時
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    // 伝票SEQ
    slipSeq: string,
    // 得意先コード
    customerCode: string,
    // 得意先名称
    customerName: string,
    // 摘要1
    remark1?: string | null,
    // 摘要2
    remark2?: string | null,
    // 注文ステータス
    orderStatus: OrderStatus,
    // 注文日
    orderedAt: string,
    // 注文日_伝票SEQ
    orderedAtSlipSeq: string,
    // 注文ステータス_注文日＿伝票SEQ
    statusOrderedAtSlipSeq: string,
    // 注文に紐づく商品名の結合文字列（品名での絞り込み検索に使用）
    // 後から品番の文字列も結合（属性名を適切な命名にしたい）
    keywordProductName?: string | null,
    // DWHからデータ連携した日時
    updatedAt: string,
  } | null,
};

export type DeleteOrderInfoMutationVariables = {
  input: DeleteOrderInfoInput,
  condition?: ModelOrderInfoConditionInput | null,
};

export type DeleteOrderInfoMutation = {
  deleteOrderInfo?:  {
    __typename: "OrderInfo",
    // 注文番号(受注NO)
    orderNo: string,
    products?:  {
      __typename: "ModelProductInfoConnection",
      items:  Array< {
        __typename: "ProductInfo",
        // 商品ID
        productId: string,
        // 注文ID（外部キー）
        orderNo: string,
        // 注文情報
        order:  {
          __typename: "OrderInfo",
          // 注文番号(受注NO)
          orderNo: string,
          // 伝票SEQ
          slipSeq: string,
          // 得意先コード
          customerCode: string,
          // 得意先名称
          customerName: string,
          // 摘要1
          remark1?: string | null,
          // 摘要2
          remark2?: string | null,
          // 注文ステータス
          orderStatus: OrderStatus,
          // 注文日
          orderedAt: string,
          // 注文日_伝票SEQ
          orderedAtSlipSeq: string,
          // 注文ステータス_注文日＿伝票SEQ
          statusOrderedAtSlipSeq: string,
          // 注文に紐づく商品名の結合文字列（品名での絞り込み検索に使用）
          // 後から品番の文字列も結合（属性名を適切な命名にしたい）
          keywordProductName?: string | null,
          // DWHからデータ連携した日時
          updatedAt: string,
        },
        // 得意先コード
        customerCode: string,
        // 得意先名称
        customerName: string,
        // 品番
        productCode: string,
        // 品名
        productName?: string | null,
        // 個数
        quantity: number,
        // 定価
        listPrice: number,
        // 単価
        unitPrice: number,
        // 金額(単価×個数)
        amount: number,
        // 注文日
        orderedAt: string,
        // 手配日
        arrangedAt?: string | null,
        // 納品予定日
        deliveryDate?: string | null,
        // 伝票日付
        slipDate?: string | null,
        // 商品ステータス
        productStatus: ProductStatus,
        // DWHからデータ連携した日時
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    // 伝票SEQ
    slipSeq: string,
    // 得意先コード
    customerCode: string,
    // 得意先名称
    customerName: string,
    // 摘要1
    remark1?: string | null,
    // 摘要2
    remark2?: string | null,
    // 注文ステータス
    orderStatus: OrderStatus,
    // 注文日
    orderedAt: string,
    // 注文日_伝票SEQ
    orderedAtSlipSeq: string,
    // 注文ステータス_注文日＿伝票SEQ
    statusOrderedAtSlipSeq: string,
    // 注文に紐づく商品名の結合文字列（品名での絞り込み検索に使用）
    // 後から品番の文字列も結合（属性名を適切な命名にしたい）
    keywordProductName?: string | null,
    // DWHからデータ連携した日時
    updatedAt: string,
  } | null,
};

export type CreateProductInfoMutationVariables = {
  input: CreateProductInfoInput,
  condition?: ModelProductInfoConditionInput | null,
};

export type CreateProductInfoMutation = {
  createProductInfo?:  {
    __typename: "ProductInfo",
    // 商品ID
    productId: string,
    // 注文ID（外部キー）
    orderNo: string,
    // 注文情報
    order:  {
      __typename: "OrderInfo",
      // 注文番号(受注NO)
      orderNo: string,
      products?:  {
        __typename: "ModelProductInfoConnection",
        items:  Array< {
          __typename: "ProductInfo",
          // 商品ID
          productId: string,
          // 注文ID（外部キー）
          orderNo: string,
          // 得意先コード
          customerCode: string,
          // 得意先名称
          customerName: string,
          // 品番
          productCode: string,
          // 品名
          productName?: string | null,
          // 個数
          quantity: number,
          // 定価
          listPrice: number,
          // 単価
          unitPrice: number,
          // 金額(単価×個数)
          amount: number,
          // 注文日
          orderedAt: string,
          // 手配日
          arrangedAt?: string | null,
          // 納品予定日
          deliveryDate?: string | null,
          // 伝票日付
          slipDate?: string | null,
          // 商品ステータス
          productStatus: ProductStatus,
          // DWHからデータ連携した日時
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      // 伝票SEQ
      slipSeq: string,
      // 得意先コード
      customerCode: string,
      // 得意先名称
      customerName: string,
      // 摘要1
      remark1?: string | null,
      // 摘要2
      remark2?: string | null,
      // 注文ステータス
      orderStatus: OrderStatus,
      // 注文日
      orderedAt: string,
      // 注文日_伝票SEQ
      orderedAtSlipSeq: string,
      // 注文ステータス_注文日＿伝票SEQ
      statusOrderedAtSlipSeq: string,
      // 注文に紐づく商品名の結合文字列（品名での絞り込み検索に使用）
      // 後から品番の文字列も結合（属性名を適切な命名にしたい）
      keywordProductName?: string | null,
      // DWHからデータ連携した日時
      updatedAt: string,
    },
    // 得意先コード
    customerCode: string,
    // 得意先名称
    customerName: string,
    // 品番
    productCode: string,
    // 品名
    productName?: string | null,
    // 個数
    quantity: number,
    // 定価
    listPrice: number,
    // 単価
    unitPrice: number,
    // 金額(単価×個数)
    amount: number,
    // 注文日
    orderedAt: string,
    // 手配日
    arrangedAt?: string | null,
    // 納品予定日
    deliveryDate?: string | null,
    // 伝票日付
    slipDate?: string | null,
    // 商品ステータス
    productStatus: ProductStatus,
    // DWHからデータ連携した日時
    updatedAt: string,
  } | null,
};

export type UpdateProductInfoMutationVariables = {
  input: UpdateProductInfoInput,
  condition?: ModelProductInfoConditionInput | null,
};

export type UpdateProductInfoMutation = {
  updateProductInfo?:  {
    __typename: "ProductInfo",
    // 商品ID
    productId: string,
    // 注文ID（外部キー）
    orderNo: string,
    // 注文情報
    order:  {
      __typename: "OrderInfo",
      // 注文番号(受注NO)
      orderNo: string,
      products?:  {
        __typename: "ModelProductInfoConnection",
        items:  Array< {
          __typename: "ProductInfo",
          // 商品ID
          productId: string,
          // 注文ID（外部キー）
          orderNo: string,
          // 得意先コード
          customerCode: string,
          // 得意先名称
          customerName: string,
          // 品番
          productCode: string,
          // 品名
          productName?: string | null,
          // 個数
          quantity: number,
          // 定価
          listPrice: number,
          // 単価
          unitPrice: number,
          // 金額(単価×個数)
          amount: number,
          // 注文日
          orderedAt: string,
          // 手配日
          arrangedAt?: string | null,
          // 納品予定日
          deliveryDate?: string | null,
          // 伝票日付
          slipDate?: string | null,
          // 商品ステータス
          productStatus: ProductStatus,
          // DWHからデータ連携した日時
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      // 伝票SEQ
      slipSeq: string,
      // 得意先コード
      customerCode: string,
      // 得意先名称
      customerName: string,
      // 摘要1
      remark1?: string | null,
      // 摘要2
      remark2?: string | null,
      // 注文ステータス
      orderStatus: OrderStatus,
      // 注文日
      orderedAt: string,
      // 注文日_伝票SEQ
      orderedAtSlipSeq: string,
      // 注文ステータス_注文日＿伝票SEQ
      statusOrderedAtSlipSeq: string,
      // 注文に紐づく商品名の結合文字列（品名での絞り込み検索に使用）
      // 後から品番の文字列も結合（属性名を適切な命名にしたい）
      keywordProductName?: string | null,
      // DWHからデータ連携した日時
      updatedAt: string,
    },
    // 得意先コード
    customerCode: string,
    // 得意先名称
    customerName: string,
    // 品番
    productCode: string,
    // 品名
    productName?: string | null,
    // 個数
    quantity: number,
    // 定価
    listPrice: number,
    // 単価
    unitPrice: number,
    // 金額(単価×個数)
    amount: number,
    // 注文日
    orderedAt: string,
    // 手配日
    arrangedAt?: string | null,
    // 納品予定日
    deliveryDate?: string | null,
    // 伝票日付
    slipDate?: string | null,
    // 商品ステータス
    productStatus: ProductStatus,
    // DWHからデータ連携した日時
    updatedAt: string,
  } | null,
};

export type DeleteProductInfoMutationVariables = {
  input: DeleteProductInfoInput,
  condition?: ModelProductInfoConditionInput | null,
};

export type DeleteProductInfoMutation = {
  deleteProductInfo?:  {
    __typename: "ProductInfo",
    // 商品ID
    productId: string,
    // 注文ID（外部キー）
    orderNo: string,
    // 注文情報
    order:  {
      __typename: "OrderInfo",
      // 注文番号(受注NO)
      orderNo: string,
      products?:  {
        __typename: "ModelProductInfoConnection",
        items:  Array< {
          __typename: "ProductInfo",
          // 商品ID
          productId: string,
          // 注文ID（外部キー）
          orderNo: string,
          // 得意先コード
          customerCode: string,
          // 得意先名称
          customerName: string,
          // 品番
          productCode: string,
          // 品名
          productName?: string | null,
          // 個数
          quantity: number,
          // 定価
          listPrice: number,
          // 単価
          unitPrice: number,
          // 金額(単価×個数)
          amount: number,
          // 注文日
          orderedAt: string,
          // 手配日
          arrangedAt?: string | null,
          // 納品予定日
          deliveryDate?: string | null,
          // 伝票日付
          slipDate?: string | null,
          // 商品ステータス
          productStatus: ProductStatus,
          // DWHからデータ連携した日時
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      // 伝票SEQ
      slipSeq: string,
      // 得意先コード
      customerCode: string,
      // 得意先名称
      customerName: string,
      // 摘要1
      remark1?: string | null,
      // 摘要2
      remark2?: string | null,
      // 注文ステータス
      orderStatus: OrderStatus,
      // 注文日
      orderedAt: string,
      // 注文日_伝票SEQ
      orderedAtSlipSeq: string,
      // 注文ステータス_注文日＿伝票SEQ
      statusOrderedAtSlipSeq: string,
      // 注文に紐づく商品名の結合文字列（品名での絞り込み検索に使用）
      // 後から品番の文字列も結合（属性名を適切な命名にしたい）
      keywordProductName?: string | null,
      // DWHからデータ連携した日時
      updatedAt: string,
    },
    // 得意先コード
    customerCode: string,
    // 得意先名称
    customerName: string,
    // 品番
    productCode: string,
    // 品名
    productName?: string | null,
    // 個数
    quantity: number,
    // 定価
    listPrice: number,
    // 単価
    unitPrice: number,
    // 金額(単価×個数)
    amount: number,
    // 注文日
    orderedAt: string,
    // 手配日
    arrangedAt?: string | null,
    // 納品予定日
    deliveryDate?: string | null,
    // 伝票日付
    slipDate?: string | null,
    // 商品ステータス
    productStatus: ProductStatus,
    // DWHからデータ連携した日時
    updatedAt: string,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    // CognitoのID
    id: string,
    // Cognitoのユーザー名
    name: string,
    // アプリの表示名
    displayName: string,
    // ロール（小川管理者・小川従業員・顧客）
    role: Role,
    // 得意先コード（外部キー）
    customerCode?: string | null,
    // 得意先ユーザーの所属企業
    customer?:  {
      __typename: "Customer",
      code: string,
      name: string,
      salesOfficeCode?: string | null,
      salesOffice?:  {
        __typename: "SalesOffice",
        code: string,
        name: string,
        customers?:  {
          __typename: "ModelCustomerConnection",
          nextToken?: string | null,
        } | null,
        employee?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employee?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          // CognitoのID
          id: string,
          // Cognitoのユーザー名
          name: string,
          // アプリの表示名
          displayName: string,
          // ロール（小川管理者・小川従業員・顧客）
          role: Role,
          // 得意先コード（外部キー）
          customerCode?: string | null,
          // 小川営業所コード（外部キー）
          salesOfficeCode?: string | null,
          // 管理者フラグ
          isAdmin?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    // 小川営業所コード（外部キー）
    salesOfficeCode?: string | null,
    // 小川従業員の所属営業所
    salesOffice?:  {
      __typename: "SalesOffice",
      code: string,
      name: string,
      customers?:  {
        __typename: "ModelCustomerConnection",
        items:  Array< {
          __typename: "Customer",
          code: string,
          name: string,
          salesOfficeCode?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      employee?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          // CognitoのID
          id: string,
          // Cognitoのユーザー名
          name: string,
          // アプリの表示名
          displayName: string,
          // ロール（小川管理者・小川従業員・顧客）
          role: Role,
          // 得意先コード（外部キー）
          customerCode?: string | null,
          // 小川営業所コード（外部キー）
          salesOfficeCode?: string | null,
          // 管理者フラグ
          isAdmin?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    // 管理者フラグ
    isAdmin?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    // CognitoのID
    id: string,
    // Cognitoのユーザー名
    name: string,
    // アプリの表示名
    displayName: string,
    // ロール（小川管理者・小川従業員・顧客）
    role: Role,
    // 得意先コード（外部キー）
    customerCode?: string | null,
    // 得意先ユーザーの所属企業
    customer?:  {
      __typename: "Customer",
      code: string,
      name: string,
      salesOfficeCode?: string | null,
      salesOffice?:  {
        __typename: "SalesOffice",
        code: string,
        name: string,
        customers?:  {
          __typename: "ModelCustomerConnection",
          nextToken?: string | null,
        } | null,
        employee?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employee?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          // CognitoのID
          id: string,
          // Cognitoのユーザー名
          name: string,
          // アプリの表示名
          displayName: string,
          // ロール（小川管理者・小川従業員・顧客）
          role: Role,
          // 得意先コード（外部キー）
          customerCode?: string | null,
          // 小川営業所コード（外部キー）
          salesOfficeCode?: string | null,
          // 管理者フラグ
          isAdmin?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    // 小川営業所コード（外部キー）
    salesOfficeCode?: string | null,
    // 小川従業員の所属営業所
    salesOffice?:  {
      __typename: "SalesOffice",
      code: string,
      name: string,
      customers?:  {
        __typename: "ModelCustomerConnection",
        items:  Array< {
          __typename: "Customer",
          code: string,
          name: string,
          salesOfficeCode?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      employee?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          // CognitoのID
          id: string,
          // Cognitoのユーザー名
          name: string,
          // アプリの表示名
          displayName: string,
          // ロール（小川管理者・小川従業員・顧客）
          role: Role,
          // 得意先コード（外部キー）
          customerCode?: string | null,
          // 小川営業所コード（外部キー）
          salesOfficeCode?: string | null,
          // 管理者フラグ
          isAdmin?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    // 管理者フラグ
    isAdmin?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    // CognitoのID
    id: string,
    // Cognitoのユーザー名
    name: string,
    // アプリの表示名
    displayName: string,
    // ロール（小川管理者・小川従業員・顧客）
    role: Role,
    // 得意先コード（外部キー）
    customerCode?: string | null,
    // 得意先ユーザーの所属企業
    customer?:  {
      __typename: "Customer",
      code: string,
      name: string,
      salesOfficeCode?: string | null,
      salesOffice?:  {
        __typename: "SalesOffice",
        code: string,
        name: string,
        customers?:  {
          __typename: "ModelCustomerConnection",
          nextToken?: string | null,
        } | null,
        employee?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employee?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          // CognitoのID
          id: string,
          // Cognitoのユーザー名
          name: string,
          // アプリの表示名
          displayName: string,
          // ロール（小川管理者・小川従業員・顧客）
          role: Role,
          // 得意先コード（外部キー）
          customerCode?: string | null,
          // 小川営業所コード（外部キー）
          salesOfficeCode?: string | null,
          // 管理者フラグ
          isAdmin?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    // 小川営業所コード（外部キー）
    salesOfficeCode?: string | null,
    // 小川従業員の所属営業所
    salesOffice?:  {
      __typename: "SalesOffice",
      code: string,
      name: string,
      customers?:  {
        __typename: "ModelCustomerConnection",
        items:  Array< {
          __typename: "Customer",
          code: string,
          name: string,
          salesOfficeCode?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      employee?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          // CognitoのID
          id: string,
          // Cognitoのユーザー名
          name: string,
          // アプリの表示名
          displayName: string,
          // ロール（小川管理者・小川従業員・顧客）
          role: Role,
          // 得意先コード（外部キー）
          customerCode?: string | null,
          // 小川営業所コード（外部キー）
          salesOfficeCode?: string | null,
          // 管理者フラグ
          isAdmin?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    // 管理者フラグ
    isAdmin?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSalesOfficeMutationVariables = {
  input: CreateSalesOfficeInput,
  condition?: ModelSalesOfficeConditionInput | null,
};

export type CreateSalesOfficeMutation = {
  createSalesOffice?:  {
    __typename: "SalesOffice",
    code: string,
    name: string,
    customers?:  {
      __typename: "ModelCustomerConnection",
      items:  Array< {
        __typename: "Customer",
        code: string,
        name: string,
        salesOfficeCode?: string | null,
        salesOffice?:  {
          __typename: "SalesOffice",
          code: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        employee?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    employee?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        // CognitoのID
        id: string,
        // Cognitoのユーザー名
        name: string,
        // アプリの表示名
        displayName: string,
        // ロール（小川管理者・小川従業員・顧客）
        role: Role,
        // 得意先コード（外部キー）
        customerCode?: string | null,
        // 得意先ユーザーの所属企業
        customer?:  {
          __typename: "Customer",
          code: string,
          name: string,
          salesOfficeCode?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        // 小川営業所コード（外部キー）
        salesOfficeCode?: string | null,
        // 小川従業員の所属営業所
        salesOffice?:  {
          __typename: "SalesOffice",
          code: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        // 管理者フラグ
        isAdmin?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSalesOfficeMutationVariables = {
  input: UpdateSalesOfficeInput,
  condition?: ModelSalesOfficeConditionInput | null,
};

export type UpdateSalesOfficeMutation = {
  updateSalesOffice?:  {
    __typename: "SalesOffice",
    code: string,
    name: string,
    customers?:  {
      __typename: "ModelCustomerConnection",
      items:  Array< {
        __typename: "Customer",
        code: string,
        name: string,
        salesOfficeCode?: string | null,
        salesOffice?:  {
          __typename: "SalesOffice",
          code: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        employee?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    employee?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        // CognitoのID
        id: string,
        // Cognitoのユーザー名
        name: string,
        // アプリの表示名
        displayName: string,
        // ロール（小川管理者・小川従業員・顧客）
        role: Role,
        // 得意先コード（外部キー）
        customerCode?: string | null,
        // 得意先ユーザーの所属企業
        customer?:  {
          __typename: "Customer",
          code: string,
          name: string,
          salesOfficeCode?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        // 小川営業所コード（外部キー）
        salesOfficeCode?: string | null,
        // 小川従業員の所属営業所
        salesOffice?:  {
          __typename: "SalesOffice",
          code: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        // 管理者フラグ
        isAdmin?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSalesOfficeMutationVariables = {
  input: DeleteSalesOfficeInput,
  condition?: ModelSalesOfficeConditionInput | null,
};

export type DeleteSalesOfficeMutation = {
  deleteSalesOffice?:  {
    __typename: "SalesOffice",
    code: string,
    name: string,
    customers?:  {
      __typename: "ModelCustomerConnection",
      items:  Array< {
        __typename: "Customer",
        code: string,
        name: string,
        salesOfficeCode?: string | null,
        salesOffice?:  {
          __typename: "SalesOffice",
          code: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        employee?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    employee?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        // CognitoのID
        id: string,
        // Cognitoのユーザー名
        name: string,
        // アプリの表示名
        displayName: string,
        // ロール（小川管理者・小川従業員・顧客）
        role: Role,
        // 得意先コード（外部キー）
        customerCode?: string | null,
        // 得意先ユーザーの所属企業
        customer?:  {
          __typename: "Customer",
          code: string,
          name: string,
          salesOfficeCode?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        // 小川営業所コード（外部キー）
        salesOfficeCode?: string | null,
        // 小川従業員の所属営業所
        salesOffice?:  {
          __typename: "SalesOffice",
          code: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        // 管理者フラグ
        isAdmin?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCustomerMutationVariables = {
  input: CreateCustomerInput,
  condition?: ModelCustomerConditionInput | null,
};

export type CreateCustomerMutation = {
  createCustomer?:  {
    __typename: "Customer",
    code: string,
    name: string,
    salesOfficeCode?: string | null,
    salesOffice?:  {
      __typename: "SalesOffice",
      code: string,
      name: string,
      customers?:  {
        __typename: "ModelCustomerConnection",
        items:  Array< {
          __typename: "Customer",
          code: string,
          name: string,
          salesOfficeCode?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      employee?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          // CognitoのID
          id: string,
          // Cognitoのユーザー名
          name: string,
          // アプリの表示名
          displayName: string,
          // ロール（小川管理者・小川従業員・顧客）
          role: Role,
          // 得意先コード（外部キー）
          customerCode?: string | null,
          // 小川営業所コード（外部キー）
          salesOfficeCode?: string | null,
          // 管理者フラグ
          isAdmin?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    employee?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        // CognitoのID
        id: string,
        // Cognitoのユーザー名
        name: string,
        // アプリの表示名
        displayName: string,
        // ロール（小川管理者・小川従業員・顧客）
        role: Role,
        // 得意先コード（外部キー）
        customerCode?: string | null,
        // 得意先ユーザーの所属企業
        customer?:  {
          __typename: "Customer",
          code: string,
          name: string,
          salesOfficeCode?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        // 小川営業所コード（外部キー）
        salesOfficeCode?: string | null,
        // 小川従業員の所属営業所
        salesOffice?:  {
          __typename: "SalesOffice",
          code: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        // 管理者フラグ
        isAdmin?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCustomerMutationVariables = {
  input: UpdateCustomerInput,
  condition?: ModelCustomerConditionInput | null,
};

export type UpdateCustomerMutation = {
  updateCustomer?:  {
    __typename: "Customer",
    code: string,
    name: string,
    salesOfficeCode?: string | null,
    salesOffice?:  {
      __typename: "SalesOffice",
      code: string,
      name: string,
      customers?:  {
        __typename: "ModelCustomerConnection",
        items:  Array< {
          __typename: "Customer",
          code: string,
          name: string,
          salesOfficeCode?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      employee?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          // CognitoのID
          id: string,
          // Cognitoのユーザー名
          name: string,
          // アプリの表示名
          displayName: string,
          // ロール（小川管理者・小川従業員・顧客）
          role: Role,
          // 得意先コード（外部キー）
          customerCode?: string | null,
          // 小川営業所コード（外部キー）
          salesOfficeCode?: string | null,
          // 管理者フラグ
          isAdmin?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    employee?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        // CognitoのID
        id: string,
        // Cognitoのユーザー名
        name: string,
        // アプリの表示名
        displayName: string,
        // ロール（小川管理者・小川従業員・顧客）
        role: Role,
        // 得意先コード（外部キー）
        customerCode?: string | null,
        // 得意先ユーザーの所属企業
        customer?:  {
          __typename: "Customer",
          code: string,
          name: string,
          salesOfficeCode?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        // 小川営業所コード（外部キー）
        salesOfficeCode?: string | null,
        // 小川従業員の所属営業所
        salesOffice?:  {
          __typename: "SalesOffice",
          code: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        // 管理者フラグ
        isAdmin?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCustomerMutationVariables = {
  input: DeleteCustomerInput,
  condition?: ModelCustomerConditionInput | null,
};

export type DeleteCustomerMutation = {
  deleteCustomer?:  {
    __typename: "Customer",
    code: string,
    name: string,
    salesOfficeCode?: string | null,
    salesOffice?:  {
      __typename: "SalesOffice",
      code: string,
      name: string,
      customers?:  {
        __typename: "ModelCustomerConnection",
        items:  Array< {
          __typename: "Customer",
          code: string,
          name: string,
          salesOfficeCode?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      employee?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          // CognitoのID
          id: string,
          // Cognitoのユーザー名
          name: string,
          // アプリの表示名
          displayName: string,
          // ロール（小川管理者・小川従業員・顧客）
          role: Role,
          // 得意先コード（外部キー）
          customerCode?: string | null,
          // 小川営業所コード（外部キー）
          salesOfficeCode?: string | null,
          // 管理者フラグ
          isAdmin?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    employee?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        // CognitoのID
        id: string,
        // Cognitoのユーザー名
        name: string,
        // アプリの表示名
        displayName: string,
        // ロール（小川管理者・小川従業員・顧客）
        role: Role,
        // 得意先コード（外部キー）
        customerCode?: string | null,
        // 得意先ユーザーの所属企業
        customer?:  {
          __typename: "Customer",
          code: string,
          name: string,
          salesOfficeCode?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        // 小川営業所コード（外部キー）
        salesOfficeCode?: string | null,
        // 小川従業員の所属営業所
        salesOffice?:  {
          __typename: "SalesOffice",
          code: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        // 管理者フラグ
        isAdmin?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSalesOfficeUserMutationVariables = {
  input: CreateSalesOfficeUserInput,
};

export type CreateSalesOfficeUserMutation = {
  createSalesOfficeUser: boolean,
};

export type CreateCustomerUserMutationVariables = {
  input: CreateCustomerUserInput,
};

export type CreateCustomerUserMutation = {
  createCustomerUser: boolean,
};

export type GetEvaluateFeatureQueryVariables = {
  feature: string,
};

export type GetEvaluateFeatureQuery = {
  getEvaluateFeature:  {
    __typename: "GetEvaluateFeatureResponse",
    details?: string | null,
    reason?: string | null,
    value?:  {
      __typename: "EvaluateFeatureValue",
      boolValue?: boolean | null,
      doubleValue?: number | null,
      longValue?: number | null,
      stringValue?: string | null,
    } | null,
    variation?: string | null,
  },
};

export type GetOrderInfoQueryVariables = {
  orderNo: string,
};

export type GetOrderInfoQuery = {
  getOrderInfo?:  {
    __typename: "OrderInfo",
    // 注文番号(受注NO)
    orderNo: string,
    products?:  {
      __typename: "ModelProductInfoConnection",
      items:  Array< {
        __typename: "ProductInfo",
        // 商品ID
        productId: string,
        // 注文ID（外部キー）
        orderNo: string,
        // 注文情報
        order:  {
          __typename: "OrderInfo",
          // 注文番号(受注NO)
          orderNo: string,
          // 伝票SEQ
          slipSeq: string,
          // 得意先コード
          customerCode: string,
          // 得意先名称
          customerName: string,
          // 摘要1
          remark1?: string | null,
          // 摘要2
          remark2?: string | null,
          // 注文ステータス
          orderStatus: OrderStatus,
          // 注文日
          orderedAt: string,
          // 注文日_伝票SEQ
          orderedAtSlipSeq: string,
          // 注文ステータス_注文日＿伝票SEQ
          statusOrderedAtSlipSeq: string,
          // 注文に紐づく商品名の結合文字列（品名での絞り込み検索に使用）
          // 後から品番の文字列も結合（属性名を適切な命名にしたい）
          keywordProductName?: string | null,
          // DWHからデータ連携した日時
          updatedAt: string,
        },
        // 得意先コード
        customerCode: string,
        // 得意先名称
        customerName: string,
        // 品番
        productCode: string,
        // 品名
        productName?: string | null,
        // 個数
        quantity: number,
        // 定価
        listPrice: number,
        // 単価
        unitPrice: number,
        // 金額(単価×個数)
        amount: number,
        // 注文日
        orderedAt: string,
        // 手配日
        arrangedAt?: string | null,
        // 納品予定日
        deliveryDate?: string | null,
        // 伝票日付
        slipDate?: string | null,
        // 商品ステータス
        productStatus: ProductStatus,
        // DWHからデータ連携した日時
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    // 伝票SEQ
    slipSeq: string,
    // 得意先コード
    customerCode: string,
    // 得意先名称
    customerName: string,
    // 摘要1
    remark1?: string | null,
    // 摘要2
    remark2?: string | null,
    // 注文ステータス
    orderStatus: OrderStatus,
    // 注文日
    orderedAt: string,
    // 注文日_伝票SEQ
    orderedAtSlipSeq: string,
    // 注文ステータス_注文日＿伝票SEQ
    statusOrderedAtSlipSeq: string,
    // 注文に紐づく商品名の結合文字列（品名での絞り込み検索に使用）
    // 後から品番の文字列も結合（属性名を適切な命名にしたい）
    keywordProductName?: string | null,
    // DWHからデータ連携した日時
    updatedAt: string,
  } | null,
};

export type ListOrderInfosQueryVariables = {
  orderNo?: string | null,
  filter?: ModelOrderInfoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOrderInfosQuery = {
  listOrderInfos?:  {
    __typename: "ModelOrderInfoConnection",
    items:  Array< {
      __typename: "OrderInfo",
      // 注文番号(受注NO)
      orderNo: string,
      products?:  {
        __typename: "ModelProductInfoConnection",
        items:  Array< {
          __typename: "ProductInfo",
          // 商品ID
          productId: string,
          // 注文ID（外部キー）
          orderNo: string,
          // 得意先コード
          customerCode: string,
          // 得意先名称
          customerName: string,
          // 品番
          productCode: string,
          // 品名
          productName?: string | null,
          // 個数
          quantity: number,
          // 定価
          listPrice: number,
          // 単価
          unitPrice: number,
          // 金額(単価×個数)
          amount: number,
          // 注文日
          orderedAt: string,
          // 手配日
          arrangedAt?: string | null,
          // 納品予定日
          deliveryDate?: string | null,
          // 伝票日付
          slipDate?: string | null,
          // 商品ステータス
          productStatus: ProductStatus,
          // DWHからデータ連携した日時
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      // 伝票SEQ
      slipSeq: string,
      // 得意先コード
      customerCode: string,
      // 得意先名称
      customerName: string,
      // 摘要1
      remark1?: string | null,
      // 摘要2
      remark2?: string | null,
      // 注文ステータス
      orderStatus: OrderStatus,
      // 注文日
      orderedAt: string,
      // 注文日_伝票SEQ
      orderedAtSlipSeq: string,
      // 注文ステータス_注文日＿伝票SEQ
      statusOrderedAtSlipSeq: string,
      // 注文に紐づく商品名の結合文字列（品名での絞り込み検索に使用）
      // 後から品番の文字列も結合（属性名を適切な命名にしたい）
      keywordProductName?: string | null,
      // DWHからデータ連携した日時
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListOrderInfoBySpecificCustomerQueryVariables = {
  customerCode: string,
  orderedAtSlipSeq?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderInfoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderInfoBySpecificCustomerQuery = {
  listOrderInfoBySpecificCustomer?:  {
    __typename: "ModelOrderInfoConnection",
    items:  Array< {
      __typename: "OrderInfo",
      // 注文番号(受注NO)
      orderNo: string,
      products?:  {
        __typename: "ModelProductInfoConnection",
        items:  Array< {
          __typename: "ProductInfo",
          // 商品ID
          productId: string,
          // 注文ID（外部キー）
          orderNo: string,
          // 得意先コード
          customerCode: string,
          // 得意先名称
          customerName: string,
          // 品番
          productCode: string,
          // 品名
          productName?: string | null,
          // 個数
          quantity: number,
          // 定価
          listPrice: number,
          // 単価
          unitPrice: number,
          // 金額(単価×個数)
          amount: number,
          // 注文日
          orderedAt: string,
          // 手配日
          arrangedAt?: string | null,
          // 納品予定日
          deliveryDate?: string | null,
          // 伝票日付
          slipDate?: string | null,
          // 商品ステータス
          productStatus: ProductStatus,
          // DWHからデータ連携した日時
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      // 伝票SEQ
      slipSeq: string,
      // 得意先コード
      customerCode: string,
      // 得意先名称
      customerName: string,
      // 摘要1
      remark1?: string | null,
      // 摘要2
      remark2?: string | null,
      // 注文ステータス
      orderStatus: OrderStatus,
      // 注文日
      orderedAt: string,
      // 注文日_伝票SEQ
      orderedAtSlipSeq: string,
      // 注文ステータス_注文日＿伝票SEQ
      statusOrderedAtSlipSeq: string,
      // 注文に紐づく商品名の結合文字列（品名での絞り込み検索に使用）
      // 後から品番の文字列も結合（属性名を適切な命名にしたい）
      keywordProductName?: string | null,
      // DWHからデータ連携した日時
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListOrderInfoBySpecificCustomerWithStatusQueryVariables = {
  customerCode: string,
  statusOrderedAtSlipSeq?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderInfoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderInfoBySpecificCustomerWithStatusQuery = {
  listOrderInfoBySpecificCustomerWithStatus?:  {
    __typename: "ModelOrderInfoConnection",
    items:  Array< {
      __typename: "OrderInfo",
      // 注文番号(受注NO)
      orderNo: string,
      products?:  {
        __typename: "ModelProductInfoConnection",
        items:  Array< {
          __typename: "ProductInfo",
          // 商品ID
          productId: string,
          // 注文ID（外部キー）
          orderNo: string,
          // 得意先コード
          customerCode: string,
          // 得意先名称
          customerName: string,
          // 品番
          productCode: string,
          // 品名
          productName?: string | null,
          // 個数
          quantity: number,
          // 定価
          listPrice: number,
          // 単価
          unitPrice: number,
          // 金額(単価×個数)
          amount: number,
          // 注文日
          orderedAt: string,
          // 手配日
          arrangedAt?: string | null,
          // 納品予定日
          deliveryDate?: string | null,
          // 伝票日付
          slipDate?: string | null,
          // 商品ステータス
          productStatus: ProductStatus,
          // DWHからデータ連携した日時
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      // 伝票SEQ
      slipSeq: string,
      // 得意先コード
      customerCode: string,
      // 得意先名称
      customerName: string,
      // 摘要1
      remark1?: string | null,
      // 摘要2
      remark2?: string | null,
      // 注文ステータス
      orderStatus: OrderStatus,
      // 注文日
      orderedAt: string,
      // 注文日_伝票SEQ
      orderedAtSlipSeq: string,
      // 注文ステータス_注文日＿伝票SEQ
      statusOrderedAtSlipSeq: string,
      // 注文に紐づく商品名の結合文字列（品名での絞り込み検索に使用）
      // 後から品番の文字列も結合（属性名を適切な命名にしたい）
      keywordProductName?: string | null,
      // DWHからデータ連携した日時
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductInfoQueryVariables = {
  productId: string,
};

export type GetProductInfoQuery = {
  getProductInfo?:  {
    __typename: "ProductInfo",
    // 商品ID
    productId: string,
    // 注文ID（外部キー）
    orderNo: string,
    // 注文情報
    order:  {
      __typename: "OrderInfo",
      // 注文番号(受注NO)
      orderNo: string,
      products?:  {
        __typename: "ModelProductInfoConnection",
        items:  Array< {
          __typename: "ProductInfo",
          // 商品ID
          productId: string,
          // 注文ID（外部キー）
          orderNo: string,
          // 得意先コード
          customerCode: string,
          // 得意先名称
          customerName: string,
          // 品番
          productCode: string,
          // 品名
          productName?: string | null,
          // 個数
          quantity: number,
          // 定価
          listPrice: number,
          // 単価
          unitPrice: number,
          // 金額(単価×個数)
          amount: number,
          // 注文日
          orderedAt: string,
          // 手配日
          arrangedAt?: string | null,
          // 納品予定日
          deliveryDate?: string | null,
          // 伝票日付
          slipDate?: string | null,
          // 商品ステータス
          productStatus: ProductStatus,
          // DWHからデータ連携した日時
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      // 伝票SEQ
      slipSeq: string,
      // 得意先コード
      customerCode: string,
      // 得意先名称
      customerName: string,
      // 摘要1
      remark1?: string | null,
      // 摘要2
      remark2?: string | null,
      // 注文ステータス
      orderStatus: OrderStatus,
      // 注文日
      orderedAt: string,
      // 注文日_伝票SEQ
      orderedAtSlipSeq: string,
      // 注文ステータス_注文日＿伝票SEQ
      statusOrderedAtSlipSeq: string,
      // 注文に紐づく商品名の結合文字列（品名での絞り込み検索に使用）
      // 後から品番の文字列も結合（属性名を適切な命名にしたい）
      keywordProductName?: string | null,
      // DWHからデータ連携した日時
      updatedAt: string,
    },
    // 得意先コード
    customerCode: string,
    // 得意先名称
    customerName: string,
    // 品番
    productCode: string,
    // 品名
    productName?: string | null,
    // 個数
    quantity: number,
    // 定価
    listPrice: number,
    // 単価
    unitPrice: number,
    // 金額(単価×個数)
    amount: number,
    // 注文日
    orderedAt: string,
    // 手配日
    arrangedAt?: string | null,
    // 納品予定日
    deliveryDate?: string | null,
    // 伝票日付
    slipDate?: string | null,
    // 商品ステータス
    productStatus: ProductStatus,
    // DWHからデータ連携した日時
    updatedAt: string,
  } | null,
};

export type ListProductInfosQueryVariables = {
  productId?: string | null,
  filter?: ModelProductInfoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListProductInfosQuery = {
  listProductInfos?:  {
    __typename: "ModelProductInfoConnection",
    items:  Array< {
      __typename: "ProductInfo",
      // 商品ID
      productId: string,
      // 注文ID（外部キー）
      orderNo: string,
      // 注文情報
      order:  {
        __typename: "OrderInfo",
        // 注文番号(受注NO)
        orderNo: string,
        products?:  {
          __typename: "ModelProductInfoConnection",
          nextToken?: string | null,
        } | null,
        // 伝票SEQ
        slipSeq: string,
        // 得意先コード
        customerCode: string,
        // 得意先名称
        customerName: string,
        // 摘要1
        remark1?: string | null,
        // 摘要2
        remark2?: string | null,
        // 注文ステータス
        orderStatus: OrderStatus,
        // 注文日
        orderedAt: string,
        // 注文日_伝票SEQ
        orderedAtSlipSeq: string,
        // 注文ステータス_注文日＿伝票SEQ
        statusOrderedAtSlipSeq: string,
        // 注文に紐づく商品名の結合文字列（品名での絞り込み検索に使用）
        // 後から品番の文字列も結合（属性名を適切な命名にしたい）
        keywordProductName?: string | null,
        // DWHからデータ連携した日時
        updatedAt: string,
      },
      // 得意先コード
      customerCode: string,
      // 得意先名称
      customerName: string,
      // 品番
      productCode: string,
      // 品名
      productName?: string | null,
      // 個数
      quantity: number,
      // 定価
      listPrice: number,
      // 単価
      unitPrice: number,
      // 金額(単価×個数)
      amount: number,
      // 注文日
      orderedAt: string,
      // 手配日
      arrangedAt?: string | null,
      // 納品予定日
      deliveryDate?: string | null,
      // 伝票日付
      slipDate?: string | null,
      // 商品ステータス
      productStatus: ProductStatus,
      // DWHからデータ連携した日時
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListProductInfoByOrderQueryVariables = {
  orderNo: string,
  productId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductInfoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductInfoByOrderQuery = {
  listProductInfoByOrder?:  {
    __typename: "ModelProductInfoConnection",
    items:  Array< {
      __typename: "ProductInfo",
      // 商品ID
      productId: string,
      // 注文ID（外部キー）
      orderNo: string,
      // 注文情報
      order:  {
        __typename: "OrderInfo",
        // 注文番号(受注NO)
        orderNo: string,
        products?:  {
          __typename: "ModelProductInfoConnection",
          nextToken?: string | null,
        } | null,
        // 伝票SEQ
        slipSeq: string,
        // 得意先コード
        customerCode: string,
        // 得意先名称
        customerName: string,
        // 摘要1
        remark1?: string | null,
        // 摘要2
        remark2?: string | null,
        // 注文ステータス
        orderStatus: OrderStatus,
        // 注文日
        orderedAt: string,
        // 注文日_伝票SEQ
        orderedAtSlipSeq: string,
        // 注文ステータス_注文日＿伝票SEQ
        statusOrderedAtSlipSeq: string,
        // 注文に紐づく商品名の結合文字列（品名での絞り込み検索に使用）
        // 後から品番の文字列も結合（属性名を適切な命名にしたい）
        keywordProductName?: string | null,
        // DWHからデータ連携した日時
        updatedAt: string,
      },
      // 得意先コード
      customerCode: string,
      // 得意先名称
      customerName: string,
      // 品番
      productCode: string,
      // 品名
      productName?: string | null,
      // 個数
      quantity: number,
      // 定価
      listPrice: number,
      // 単価
      unitPrice: number,
      // 金額(単価×個数)
      amount: number,
      // 注文日
      orderedAt: string,
      // 手配日
      arrangedAt?: string | null,
      // 納品予定日
      deliveryDate?: string | null,
      // 伝票日付
      slipDate?: string | null,
      // 商品ステータス
      productStatus: ProductStatus,
      // DWHからデータ連携した日時
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    // CognitoのID
    id: string,
    // Cognitoのユーザー名
    name: string,
    // アプリの表示名
    displayName: string,
    // ロール（小川管理者・小川従業員・顧客）
    role: Role,
    // 得意先コード（外部キー）
    customerCode?: string | null,
    // 得意先ユーザーの所属企業
    customer?:  {
      __typename: "Customer",
      code: string,
      name: string,
      salesOfficeCode?: string | null,
      salesOffice?:  {
        __typename: "SalesOffice",
        code: string,
        name: string,
        customers?:  {
          __typename: "ModelCustomerConnection",
          nextToken?: string | null,
        } | null,
        employee?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employee?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          // CognitoのID
          id: string,
          // Cognitoのユーザー名
          name: string,
          // アプリの表示名
          displayName: string,
          // ロール（小川管理者・小川従業員・顧客）
          role: Role,
          // 得意先コード（外部キー）
          customerCode?: string | null,
          // 小川営業所コード（外部キー）
          salesOfficeCode?: string | null,
          // 管理者フラグ
          isAdmin?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    // 小川営業所コード（外部キー）
    salesOfficeCode?: string | null,
    // 小川従業員の所属営業所
    salesOffice?:  {
      __typename: "SalesOffice",
      code: string,
      name: string,
      customers?:  {
        __typename: "ModelCustomerConnection",
        items:  Array< {
          __typename: "Customer",
          code: string,
          name: string,
          salesOfficeCode?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      employee?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          // CognitoのID
          id: string,
          // Cognitoのユーザー名
          name: string,
          // アプリの表示名
          displayName: string,
          // ロール（小川管理者・小川従業員・顧客）
          role: Role,
          // 得意先コード（外部キー）
          customerCode?: string | null,
          // 小川営業所コード（外部キー）
          salesOfficeCode?: string | null,
          // 管理者フラグ
          isAdmin?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    // 管理者フラグ
    isAdmin?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      // CognitoのID
      id: string,
      // Cognitoのユーザー名
      name: string,
      // アプリの表示名
      displayName: string,
      // ロール（小川管理者・小川従業員・顧客）
      role: Role,
      // 得意先コード（外部キー）
      customerCode?: string | null,
      // 得意先ユーザーの所属企業
      customer?:  {
        __typename: "Customer",
        code: string,
        name: string,
        salesOfficeCode?: string | null,
        salesOffice?:  {
          __typename: "SalesOffice",
          code: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        employee?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      // 小川営業所コード（外部キー）
      salesOfficeCode?: string | null,
      // 小川従業員の所属営業所
      salesOffice?:  {
        __typename: "SalesOffice",
        code: string,
        name: string,
        customers?:  {
          __typename: "ModelCustomerConnection",
          nextToken?: string | null,
        } | null,
        employee?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      // 管理者フラグ
      isAdmin?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUsersByCustomerQueryVariables = {
  customerCode: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersByCustomerQuery = {
  listUsersByCustomer?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      // CognitoのID
      id: string,
      // Cognitoのユーザー名
      name: string,
      // アプリの表示名
      displayName: string,
      // ロール（小川管理者・小川従業員・顧客）
      role: Role,
      // 得意先コード（外部キー）
      customerCode?: string | null,
      // 得意先ユーザーの所属企業
      customer?:  {
        __typename: "Customer",
        code: string,
        name: string,
        salesOfficeCode?: string | null,
        salesOffice?:  {
          __typename: "SalesOffice",
          code: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        employee?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      // 小川営業所コード（外部キー）
      salesOfficeCode?: string | null,
      // 小川従業員の所属営業所
      salesOffice?:  {
        __typename: "SalesOffice",
        code: string,
        name: string,
        customers?:  {
          __typename: "ModelCustomerConnection",
          nextToken?: string | null,
        } | null,
        employee?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      // 管理者フラグ
      isAdmin?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUsersBySalesOfficeQueryVariables = {
  salesOfficeCode: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersBySalesOfficeQuery = {
  listUsersBySalesOffice?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      // CognitoのID
      id: string,
      // Cognitoのユーザー名
      name: string,
      // アプリの表示名
      displayName: string,
      // ロール（小川管理者・小川従業員・顧客）
      role: Role,
      // 得意先コード（外部キー）
      customerCode?: string | null,
      // 得意先ユーザーの所属企業
      customer?:  {
        __typename: "Customer",
        code: string,
        name: string,
        salesOfficeCode?: string | null,
        salesOffice?:  {
          __typename: "SalesOffice",
          code: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        employee?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      // 小川営業所コード（外部キー）
      salesOfficeCode?: string | null,
      // 小川従業員の所属営業所
      salesOffice?:  {
        __typename: "SalesOffice",
        code: string,
        name: string,
        customers?:  {
          __typename: "ModelCustomerConnection",
          nextToken?: string | null,
        } | null,
        employee?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      // 管理者フラグ
      isAdmin?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSalesOfficeQueryVariables = {
  code: string,
};

export type GetSalesOfficeQuery = {
  getSalesOffice?:  {
    __typename: "SalesOffice",
    code: string,
    name: string,
    customers?:  {
      __typename: "ModelCustomerConnection",
      items:  Array< {
        __typename: "Customer",
        code: string,
        name: string,
        salesOfficeCode?: string | null,
        salesOffice?:  {
          __typename: "SalesOffice",
          code: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        employee?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    employee?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        // CognitoのID
        id: string,
        // Cognitoのユーザー名
        name: string,
        // アプリの表示名
        displayName: string,
        // ロール（小川管理者・小川従業員・顧客）
        role: Role,
        // 得意先コード（外部キー）
        customerCode?: string | null,
        // 得意先ユーザーの所属企業
        customer?:  {
          __typename: "Customer",
          code: string,
          name: string,
          salesOfficeCode?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        // 小川営業所コード（外部キー）
        salesOfficeCode?: string | null,
        // 小川従業員の所属営業所
        salesOffice?:  {
          __typename: "SalesOffice",
          code: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        // 管理者フラグ
        isAdmin?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSalesOfficesQueryVariables = {
  code?: string | null,
  filter?: ModelSalesOfficeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListSalesOfficesQuery = {
  listSalesOffices?:  {
    __typename: "ModelSalesOfficeConnection",
    items:  Array< {
      __typename: "SalesOffice",
      code: string,
      name: string,
      customers?:  {
        __typename: "ModelCustomerConnection",
        items:  Array< {
          __typename: "Customer",
          code: string,
          name: string,
          salesOfficeCode?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      employee?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          // CognitoのID
          id: string,
          // Cognitoのユーザー名
          name: string,
          // アプリの表示名
          displayName: string,
          // ロール（小川管理者・小川従業員・顧客）
          role: Role,
          // 得意先コード（外部キー）
          customerCode?: string | null,
          // 小川営業所コード（外部キー）
          salesOfficeCode?: string | null,
          // 管理者フラグ
          isAdmin?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCustomerQueryVariables = {
  code: string,
};

export type GetCustomerQuery = {
  getCustomer?:  {
    __typename: "Customer",
    code: string,
    name: string,
    salesOfficeCode?: string | null,
    salesOffice?:  {
      __typename: "SalesOffice",
      code: string,
      name: string,
      customers?:  {
        __typename: "ModelCustomerConnection",
        items:  Array< {
          __typename: "Customer",
          code: string,
          name: string,
          salesOfficeCode?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      employee?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          // CognitoのID
          id: string,
          // Cognitoのユーザー名
          name: string,
          // アプリの表示名
          displayName: string,
          // ロール（小川管理者・小川従業員・顧客）
          role: Role,
          // 得意先コード（外部キー）
          customerCode?: string | null,
          // 小川営業所コード（外部キー）
          salesOfficeCode?: string | null,
          // 管理者フラグ
          isAdmin?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    employee?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        // CognitoのID
        id: string,
        // Cognitoのユーザー名
        name: string,
        // アプリの表示名
        displayName: string,
        // ロール（小川管理者・小川従業員・顧客）
        role: Role,
        // 得意先コード（外部キー）
        customerCode?: string | null,
        // 得意先ユーザーの所属企業
        customer?:  {
          __typename: "Customer",
          code: string,
          name: string,
          salesOfficeCode?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        // 小川営業所コード（外部キー）
        salesOfficeCode?: string | null,
        // 小川従業員の所属営業所
        salesOffice?:  {
          __typename: "SalesOffice",
          code: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        // 管理者フラグ
        isAdmin?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCustomersQueryVariables = {
  code?: string | null,
  filter?: ModelCustomerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCustomersQuery = {
  listCustomers?:  {
    __typename: "ModelCustomerConnection",
    items:  Array< {
      __typename: "Customer",
      code: string,
      name: string,
      salesOfficeCode?: string | null,
      salesOffice?:  {
        __typename: "SalesOffice",
        code: string,
        name: string,
        customers?:  {
          __typename: "ModelCustomerConnection",
          nextToken?: string | null,
        } | null,
        employee?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employee?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          // CognitoのID
          id: string,
          // Cognitoのユーザー名
          name: string,
          // アプリの表示名
          displayName: string,
          // ロール（小川管理者・小川従業員・顧客）
          role: Role,
          // 得意先コード（外部キー）
          customerCode?: string | null,
          // 小川営業所コード（外部キー）
          salesOfficeCode?: string | null,
          // 管理者フラグ
          isAdmin?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCustomerBySalesOfficeQueryVariables = {
  salesOfficeCode: string,
  code?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCustomerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCustomerBySalesOfficeQuery = {
  listCustomerBySalesOffice?:  {
    __typename: "ModelCustomerConnection",
    items:  Array< {
      __typename: "Customer",
      code: string,
      name: string,
      salesOfficeCode?: string | null,
      salesOffice?:  {
        __typename: "SalesOffice",
        code: string,
        name: string,
        customers?:  {
          __typename: "ModelCustomerConnection",
          nextToken?: string | null,
        } | null,
        employee?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employee?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          // CognitoのID
          id: string,
          // Cognitoのユーザー名
          name: string,
          // アプリの表示名
          displayName: string,
          // ロール（小川管理者・小川従業員・顧客）
          role: Role,
          // 得意先コード（外部キー）
          customerCode?: string | null,
          // 小川営業所コード（外部キー）
          salesOfficeCode?: string | null,
          // 管理者フラグ
          isAdmin?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateOrderInfoSubscriptionVariables = {
  filter?: ModelSubscriptionOrderInfoFilterInput | null,
};

export type OnCreateOrderInfoSubscription = {
  onCreateOrderInfo?:  {
    __typename: "OrderInfo",
    // 注文番号(受注NO)
    orderNo: string,
    products?:  {
      __typename: "ModelProductInfoConnection",
      items:  Array< {
        __typename: "ProductInfo",
        // 商品ID
        productId: string,
        // 注文ID（外部キー）
        orderNo: string,
        // 注文情報
        order:  {
          __typename: "OrderInfo",
          // 注文番号(受注NO)
          orderNo: string,
          // 伝票SEQ
          slipSeq: string,
          // 得意先コード
          customerCode: string,
          // 得意先名称
          customerName: string,
          // 摘要1
          remark1?: string | null,
          // 摘要2
          remark2?: string | null,
          // 注文ステータス
          orderStatus: OrderStatus,
          // 注文日
          orderedAt: string,
          // 注文日_伝票SEQ
          orderedAtSlipSeq: string,
          // 注文ステータス_注文日＿伝票SEQ
          statusOrderedAtSlipSeq: string,
          // 注文に紐づく商品名の結合文字列（品名での絞り込み検索に使用）
          // 後から品番の文字列も結合（属性名を適切な命名にしたい）
          keywordProductName?: string | null,
          // DWHからデータ連携した日時
          updatedAt: string,
        },
        // 得意先コード
        customerCode: string,
        // 得意先名称
        customerName: string,
        // 品番
        productCode: string,
        // 品名
        productName?: string | null,
        // 個数
        quantity: number,
        // 定価
        listPrice: number,
        // 単価
        unitPrice: number,
        // 金額(単価×個数)
        amount: number,
        // 注文日
        orderedAt: string,
        // 手配日
        arrangedAt?: string | null,
        // 納品予定日
        deliveryDate?: string | null,
        // 伝票日付
        slipDate?: string | null,
        // 商品ステータス
        productStatus: ProductStatus,
        // DWHからデータ連携した日時
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    // 伝票SEQ
    slipSeq: string,
    // 得意先コード
    customerCode: string,
    // 得意先名称
    customerName: string,
    // 摘要1
    remark1?: string | null,
    // 摘要2
    remark2?: string | null,
    // 注文ステータス
    orderStatus: OrderStatus,
    // 注文日
    orderedAt: string,
    // 注文日_伝票SEQ
    orderedAtSlipSeq: string,
    // 注文ステータス_注文日＿伝票SEQ
    statusOrderedAtSlipSeq: string,
    // 注文に紐づく商品名の結合文字列（品名での絞り込み検索に使用）
    // 後から品番の文字列も結合（属性名を適切な命名にしたい）
    keywordProductName?: string | null,
    // DWHからデータ連携した日時
    updatedAt: string,
  } | null,
};

export type OnUpdateOrderInfoSubscriptionVariables = {
  filter?: ModelSubscriptionOrderInfoFilterInput | null,
};

export type OnUpdateOrderInfoSubscription = {
  onUpdateOrderInfo?:  {
    __typename: "OrderInfo",
    // 注文番号(受注NO)
    orderNo: string,
    products?:  {
      __typename: "ModelProductInfoConnection",
      items:  Array< {
        __typename: "ProductInfo",
        // 商品ID
        productId: string,
        // 注文ID（外部キー）
        orderNo: string,
        // 注文情報
        order:  {
          __typename: "OrderInfo",
          // 注文番号(受注NO)
          orderNo: string,
          // 伝票SEQ
          slipSeq: string,
          // 得意先コード
          customerCode: string,
          // 得意先名称
          customerName: string,
          // 摘要1
          remark1?: string | null,
          // 摘要2
          remark2?: string | null,
          // 注文ステータス
          orderStatus: OrderStatus,
          // 注文日
          orderedAt: string,
          // 注文日_伝票SEQ
          orderedAtSlipSeq: string,
          // 注文ステータス_注文日＿伝票SEQ
          statusOrderedAtSlipSeq: string,
          // 注文に紐づく商品名の結合文字列（品名での絞り込み検索に使用）
          // 後から品番の文字列も結合（属性名を適切な命名にしたい）
          keywordProductName?: string | null,
          // DWHからデータ連携した日時
          updatedAt: string,
        },
        // 得意先コード
        customerCode: string,
        // 得意先名称
        customerName: string,
        // 品番
        productCode: string,
        // 品名
        productName?: string | null,
        // 個数
        quantity: number,
        // 定価
        listPrice: number,
        // 単価
        unitPrice: number,
        // 金額(単価×個数)
        amount: number,
        // 注文日
        orderedAt: string,
        // 手配日
        arrangedAt?: string | null,
        // 納品予定日
        deliveryDate?: string | null,
        // 伝票日付
        slipDate?: string | null,
        // 商品ステータス
        productStatus: ProductStatus,
        // DWHからデータ連携した日時
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    // 伝票SEQ
    slipSeq: string,
    // 得意先コード
    customerCode: string,
    // 得意先名称
    customerName: string,
    // 摘要1
    remark1?: string | null,
    // 摘要2
    remark2?: string | null,
    // 注文ステータス
    orderStatus: OrderStatus,
    // 注文日
    orderedAt: string,
    // 注文日_伝票SEQ
    orderedAtSlipSeq: string,
    // 注文ステータス_注文日＿伝票SEQ
    statusOrderedAtSlipSeq: string,
    // 注文に紐づく商品名の結合文字列（品名での絞り込み検索に使用）
    // 後から品番の文字列も結合（属性名を適切な命名にしたい）
    keywordProductName?: string | null,
    // DWHからデータ連携した日時
    updatedAt: string,
  } | null,
};

export type OnDeleteOrderInfoSubscriptionVariables = {
  filter?: ModelSubscriptionOrderInfoFilterInput | null,
};

export type OnDeleteOrderInfoSubscription = {
  onDeleteOrderInfo?:  {
    __typename: "OrderInfo",
    // 注文番号(受注NO)
    orderNo: string,
    products?:  {
      __typename: "ModelProductInfoConnection",
      items:  Array< {
        __typename: "ProductInfo",
        // 商品ID
        productId: string,
        // 注文ID（外部キー）
        orderNo: string,
        // 注文情報
        order:  {
          __typename: "OrderInfo",
          // 注文番号(受注NO)
          orderNo: string,
          // 伝票SEQ
          slipSeq: string,
          // 得意先コード
          customerCode: string,
          // 得意先名称
          customerName: string,
          // 摘要1
          remark1?: string | null,
          // 摘要2
          remark2?: string | null,
          // 注文ステータス
          orderStatus: OrderStatus,
          // 注文日
          orderedAt: string,
          // 注文日_伝票SEQ
          orderedAtSlipSeq: string,
          // 注文ステータス_注文日＿伝票SEQ
          statusOrderedAtSlipSeq: string,
          // 注文に紐づく商品名の結合文字列（品名での絞り込み検索に使用）
          // 後から品番の文字列も結合（属性名を適切な命名にしたい）
          keywordProductName?: string | null,
          // DWHからデータ連携した日時
          updatedAt: string,
        },
        // 得意先コード
        customerCode: string,
        // 得意先名称
        customerName: string,
        // 品番
        productCode: string,
        // 品名
        productName?: string | null,
        // 個数
        quantity: number,
        // 定価
        listPrice: number,
        // 単価
        unitPrice: number,
        // 金額(単価×個数)
        amount: number,
        // 注文日
        orderedAt: string,
        // 手配日
        arrangedAt?: string | null,
        // 納品予定日
        deliveryDate?: string | null,
        // 伝票日付
        slipDate?: string | null,
        // 商品ステータス
        productStatus: ProductStatus,
        // DWHからデータ連携した日時
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    // 伝票SEQ
    slipSeq: string,
    // 得意先コード
    customerCode: string,
    // 得意先名称
    customerName: string,
    // 摘要1
    remark1?: string | null,
    // 摘要2
    remark2?: string | null,
    // 注文ステータス
    orderStatus: OrderStatus,
    // 注文日
    orderedAt: string,
    // 注文日_伝票SEQ
    orderedAtSlipSeq: string,
    // 注文ステータス_注文日＿伝票SEQ
    statusOrderedAtSlipSeq: string,
    // 注文に紐づく商品名の結合文字列（品名での絞り込み検索に使用）
    // 後から品番の文字列も結合（属性名を適切な命名にしたい）
    keywordProductName?: string | null,
    // DWHからデータ連携した日時
    updatedAt: string,
  } | null,
};

export type OnCreateProductInfoSubscriptionVariables = {
  filter?: ModelSubscriptionProductInfoFilterInput | null,
};

export type OnCreateProductInfoSubscription = {
  onCreateProductInfo?:  {
    __typename: "ProductInfo",
    // 商品ID
    productId: string,
    // 注文ID（外部キー）
    orderNo: string,
    // 注文情報
    order:  {
      __typename: "OrderInfo",
      // 注文番号(受注NO)
      orderNo: string,
      products?:  {
        __typename: "ModelProductInfoConnection",
        items:  Array< {
          __typename: "ProductInfo",
          // 商品ID
          productId: string,
          // 注文ID（外部キー）
          orderNo: string,
          // 得意先コード
          customerCode: string,
          // 得意先名称
          customerName: string,
          // 品番
          productCode: string,
          // 品名
          productName?: string | null,
          // 個数
          quantity: number,
          // 定価
          listPrice: number,
          // 単価
          unitPrice: number,
          // 金額(単価×個数)
          amount: number,
          // 注文日
          orderedAt: string,
          // 手配日
          arrangedAt?: string | null,
          // 納品予定日
          deliveryDate?: string | null,
          // 伝票日付
          slipDate?: string | null,
          // 商品ステータス
          productStatus: ProductStatus,
          // DWHからデータ連携した日時
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      // 伝票SEQ
      slipSeq: string,
      // 得意先コード
      customerCode: string,
      // 得意先名称
      customerName: string,
      // 摘要1
      remark1?: string | null,
      // 摘要2
      remark2?: string | null,
      // 注文ステータス
      orderStatus: OrderStatus,
      // 注文日
      orderedAt: string,
      // 注文日_伝票SEQ
      orderedAtSlipSeq: string,
      // 注文ステータス_注文日＿伝票SEQ
      statusOrderedAtSlipSeq: string,
      // 注文に紐づく商品名の結合文字列（品名での絞り込み検索に使用）
      // 後から品番の文字列も結合（属性名を適切な命名にしたい）
      keywordProductName?: string | null,
      // DWHからデータ連携した日時
      updatedAt: string,
    },
    // 得意先コード
    customerCode: string,
    // 得意先名称
    customerName: string,
    // 品番
    productCode: string,
    // 品名
    productName?: string | null,
    // 個数
    quantity: number,
    // 定価
    listPrice: number,
    // 単価
    unitPrice: number,
    // 金額(単価×個数)
    amount: number,
    // 注文日
    orderedAt: string,
    // 手配日
    arrangedAt?: string | null,
    // 納品予定日
    deliveryDate?: string | null,
    // 伝票日付
    slipDate?: string | null,
    // 商品ステータス
    productStatus: ProductStatus,
    // DWHからデータ連携した日時
    updatedAt: string,
  } | null,
};

export type OnUpdateProductInfoSubscriptionVariables = {
  filter?: ModelSubscriptionProductInfoFilterInput | null,
};

export type OnUpdateProductInfoSubscription = {
  onUpdateProductInfo?:  {
    __typename: "ProductInfo",
    // 商品ID
    productId: string,
    // 注文ID（外部キー）
    orderNo: string,
    // 注文情報
    order:  {
      __typename: "OrderInfo",
      // 注文番号(受注NO)
      orderNo: string,
      products?:  {
        __typename: "ModelProductInfoConnection",
        items:  Array< {
          __typename: "ProductInfo",
          // 商品ID
          productId: string,
          // 注文ID（外部キー）
          orderNo: string,
          // 得意先コード
          customerCode: string,
          // 得意先名称
          customerName: string,
          // 品番
          productCode: string,
          // 品名
          productName?: string | null,
          // 個数
          quantity: number,
          // 定価
          listPrice: number,
          // 単価
          unitPrice: number,
          // 金額(単価×個数)
          amount: number,
          // 注文日
          orderedAt: string,
          // 手配日
          arrangedAt?: string | null,
          // 納品予定日
          deliveryDate?: string | null,
          // 伝票日付
          slipDate?: string | null,
          // 商品ステータス
          productStatus: ProductStatus,
          // DWHからデータ連携した日時
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      // 伝票SEQ
      slipSeq: string,
      // 得意先コード
      customerCode: string,
      // 得意先名称
      customerName: string,
      // 摘要1
      remark1?: string | null,
      // 摘要2
      remark2?: string | null,
      // 注文ステータス
      orderStatus: OrderStatus,
      // 注文日
      orderedAt: string,
      // 注文日_伝票SEQ
      orderedAtSlipSeq: string,
      // 注文ステータス_注文日＿伝票SEQ
      statusOrderedAtSlipSeq: string,
      // 注文に紐づく商品名の結合文字列（品名での絞り込み検索に使用）
      // 後から品番の文字列も結合（属性名を適切な命名にしたい）
      keywordProductName?: string | null,
      // DWHからデータ連携した日時
      updatedAt: string,
    },
    // 得意先コード
    customerCode: string,
    // 得意先名称
    customerName: string,
    // 品番
    productCode: string,
    // 品名
    productName?: string | null,
    // 個数
    quantity: number,
    // 定価
    listPrice: number,
    // 単価
    unitPrice: number,
    // 金額(単価×個数)
    amount: number,
    // 注文日
    orderedAt: string,
    // 手配日
    arrangedAt?: string | null,
    // 納品予定日
    deliveryDate?: string | null,
    // 伝票日付
    slipDate?: string | null,
    // 商品ステータス
    productStatus: ProductStatus,
    // DWHからデータ連携した日時
    updatedAt: string,
  } | null,
};

export type OnDeleteProductInfoSubscriptionVariables = {
  filter?: ModelSubscriptionProductInfoFilterInput | null,
};

export type OnDeleteProductInfoSubscription = {
  onDeleteProductInfo?:  {
    __typename: "ProductInfo",
    // 商品ID
    productId: string,
    // 注文ID（外部キー）
    orderNo: string,
    // 注文情報
    order:  {
      __typename: "OrderInfo",
      // 注文番号(受注NO)
      orderNo: string,
      products?:  {
        __typename: "ModelProductInfoConnection",
        items:  Array< {
          __typename: "ProductInfo",
          // 商品ID
          productId: string,
          // 注文ID（外部キー）
          orderNo: string,
          // 得意先コード
          customerCode: string,
          // 得意先名称
          customerName: string,
          // 品番
          productCode: string,
          // 品名
          productName?: string | null,
          // 個数
          quantity: number,
          // 定価
          listPrice: number,
          // 単価
          unitPrice: number,
          // 金額(単価×個数)
          amount: number,
          // 注文日
          orderedAt: string,
          // 手配日
          arrangedAt?: string | null,
          // 納品予定日
          deliveryDate?: string | null,
          // 伝票日付
          slipDate?: string | null,
          // 商品ステータス
          productStatus: ProductStatus,
          // DWHからデータ連携した日時
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      // 伝票SEQ
      slipSeq: string,
      // 得意先コード
      customerCode: string,
      // 得意先名称
      customerName: string,
      // 摘要1
      remark1?: string | null,
      // 摘要2
      remark2?: string | null,
      // 注文ステータス
      orderStatus: OrderStatus,
      // 注文日
      orderedAt: string,
      // 注文日_伝票SEQ
      orderedAtSlipSeq: string,
      // 注文ステータス_注文日＿伝票SEQ
      statusOrderedAtSlipSeq: string,
      // 注文に紐づく商品名の結合文字列（品名での絞り込み検索に使用）
      // 後から品番の文字列も結合（属性名を適切な命名にしたい）
      keywordProductName?: string | null,
      // DWHからデータ連携した日時
      updatedAt: string,
    },
    // 得意先コード
    customerCode: string,
    // 得意先名称
    customerName: string,
    // 品番
    productCode: string,
    // 品名
    productName?: string | null,
    // 個数
    quantity: number,
    // 定価
    listPrice: number,
    // 単価
    unitPrice: number,
    // 金額(単価×個数)
    amount: number,
    // 注文日
    orderedAt: string,
    // 手配日
    arrangedAt?: string | null,
    // 納品予定日
    deliveryDate?: string | null,
    // 伝票日付
    slipDate?: string | null,
    // 商品ステータス
    productStatus: ProductStatus,
    // DWHからデータ連携した日時
    updatedAt: string,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    // CognitoのID
    id: string,
    // Cognitoのユーザー名
    name: string,
    // アプリの表示名
    displayName: string,
    // ロール（小川管理者・小川従業員・顧客）
    role: Role,
    // 得意先コード（外部キー）
    customerCode?: string | null,
    // 得意先ユーザーの所属企業
    customer?:  {
      __typename: "Customer",
      code: string,
      name: string,
      salesOfficeCode?: string | null,
      salesOffice?:  {
        __typename: "SalesOffice",
        code: string,
        name: string,
        customers?:  {
          __typename: "ModelCustomerConnection",
          nextToken?: string | null,
        } | null,
        employee?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employee?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          // CognitoのID
          id: string,
          // Cognitoのユーザー名
          name: string,
          // アプリの表示名
          displayName: string,
          // ロール（小川管理者・小川従業員・顧客）
          role: Role,
          // 得意先コード（外部キー）
          customerCode?: string | null,
          // 小川営業所コード（外部キー）
          salesOfficeCode?: string | null,
          // 管理者フラグ
          isAdmin?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    // 小川営業所コード（外部キー）
    salesOfficeCode?: string | null,
    // 小川従業員の所属営業所
    salesOffice?:  {
      __typename: "SalesOffice",
      code: string,
      name: string,
      customers?:  {
        __typename: "ModelCustomerConnection",
        items:  Array< {
          __typename: "Customer",
          code: string,
          name: string,
          salesOfficeCode?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      employee?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          // CognitoのID
          id: string,
          // Cognitoのユーザー名
          name: string,
          // アプリの表示名
          displayName: string,
          // ロール（小川管理者・小川従業員・顧客）
          role: Role,
          // 得意先コード（外部キー）
          customerCode?: string | null,
          // 小川営業所コード（外部キー）
          salesOfficeCode?: string | null,
          // 管理者フラグ
          isAdmin?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    // 管理者フラグ
    isAdmin?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    // CognitoのID
    id: string,
    // Cognitoのユーザー名
    name: string,
    // アプリの表示名
    displayName: string,
    // ロール（小川管理者・小川従業員・顧客）
    role: Role,
    // 得意先コード（外部キー）
    customerCode?: string | null,
    // 得意先ユーザーの所属企業
    customer?:  {
      __typename: "Customer",
      code: string,
      name: string,
      salesOfficeCode?: string | null,
      salesOffice?:  {
        __typename: "SalesOffice",
        code: string,
        name: string,
        customers?:  {
          __typename: "ModelCustomerConnection",
          nextToken?: string | null,
        } | null,
        employee?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employee?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          // CognitoのID
          id: string,
          // Cognitoのユーザー名
          name: string,
          // アプリの表示名
          displayName: string,
          // ロール（小川管理者・小川従業員・顧客）
          role: Role,
          // 得意先コード（外部キー）
          customerCode?: string | null,
          // 小川営業所コード（外部キー）
          salesOfficeCode?: string | null,
          // 管理者フラグ
          isAdmin?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    // 小川営業所コード（外部キー）
    salesOfficeCode?: string | null,
    // 小川従業員の所属営業所
    salesOffice?:  {
      __typename: "SalesOffice",
      code: string,
      name: string,
      customers?:  {
        __typename: "ModelCustomerConnection",
        items:  Array< {
          __typename: "Customer",
          code: string,
          name: string,
          salesOfficeCode?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      employee?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          // CognitoのID
          id: string,
          // Cognitoのユーザー名
          name: string,
          // アプリの表示名
          displayName: string,
          // ロール（小川管理者・小川従業員・顧客）
          role: Role,
          // 得意先コード（外部キー）
          customerCode?: string | null,
          // 小川営業所コード（外部キー）
          salesOfficeCode?: string | null,
          // 管理者フラグ
          isAdmin?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    // 管理者フラグ
    isAdmin?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    // CognitoのID
    id: string,
    // Cognitoのユーザー名
    name: string,
    // アプリの表示名
    displayName: string,
    // ロール（小川管理者・小川従業員・顧客）
    role: Role,
    // 得意先コード（外部キー）
    customerCode?: string | null,
    // 得意先ユーザーの所属企業
    customer?:  {
      __typename: "Customer",
      code: string,
      name: string,
      salesOfficeCode?: string | null,
      salesOffice?:  {
        __typename: "SalesOffice",
        code: string,
        name: string,
        customers?:  {
          __typename: "ModelCustomerConnection",
          nextToken?: string | null,
        } | null,
        employee?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employee?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          // CognitoのID
          id: string,
          // Cognitoのユーザー名
          name: string,
          // アプリの表示名
          displayName: string,
          // ロール（小川管理者・小川従業員・顧客）
          role: Role,
          // 得意先コード（外部キー）
          customerCode?: string | null,
          // 小川営業所コード（外部キー）
          salesOfficeCode?: string | null,
          // 管理者フラグ
          isAdmin?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    // 小川営業所コード（外部キー）
    salesOfficeCode?: string | null,
    // 小川従業員の所属営業所
    salesOffice?:  {
      __typename: "SalesOffice",
      code: string,
      name: string,
      customers?:  {
        __typename: "ModelCustomerConnection",
        items:  Array< {
          __typename: "Customer",
          code: string,
          name: string,
          salesOfficeCode?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      employee?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          // CognitoのID
          id: string,
          // Cognitoのユーザー名
          name: string,
          // アプリの表示名
          displayName: string,
          // ロール（小川管理者・小川従業員・顧客）
          role: Role,
          // 得意先コード（外部キー）
          customerCode?: string | null,
          // 小川営業所コード（外部キー）
          salesOfficeCode?: string | null,
          // 管理者フラグ
          isAdmin?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    // 管理者フラグ
    isAdmin?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSalesOfficeSubscriptionVariables = {
  filter?: ModelSubscriptionSalesOfficeFilterInput | null,
};

export type OnCreateSalesOfficeSubscription = {
  onCreateSalesOffice?:  {
    __typename: "SalesOffice",
    code: string,
    name: string,
    customers?:  {
      __typename: "ModelCustomerConnection",
      items:  Array< {
        __typename: "Customer",
        code: string,
        name: string,
        salesOfficeCode?: string | null,
        salesOffice?:  {
          __typename: "SalesOffice",
          code: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        employee?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    employee?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        // CognitoのID
        id: string,
        // Cognitoのユーザー名
        name: string,
        // アプリの表示名
        displayName: string,
        // ロール（小川管理者・小川従業員・顧客）
        role: Role,
        // 得意先コード（外部キー）
        customerCode?: string | null,
        // 得意先ユーザーの所属企業
        customer?:  {
          __typename: "Customer",
          code: string,
          name: string,
          salesOfficeCode?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        // 小川営業所コード（外部キー）
        salesOfficeCode?: string | null,
        // 小川従業員の所属営業所
        salesOffice?:  {
          __typename: "SalesOffice",
          code: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        // 管理者フラグ
        isAdmin?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSalesOfficeSubscriptionVariables = {
  filter?: ModelSubscriptionSalesOfficeFilterInput | null,
};

export type OnUpdateSalesOfficeSubscription = {
  onUpdateSalesOffice?:  {
    __typename: "SalesOffice",
    code: string,
    name: string,
    customers?:  {
      __typename: "ModelCustomerConnection",
      items:  Array< {
        __typename: "Customer",
        code: string,
        name: string,
        salesOfficeCode?: string | null,
        salesOffice?:  {
          __typename: "SalesOffice",
          code: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        employee?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    employee?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        // CognitoのID
        id: string,
        // Cognitoのユーザー名
        name: string,
        // アプリの表示名
        displayName: string,
        // ロール（小川管理者・小川従業員・顧客）
        role: Role,
        // 得意先コード（外部キー）
        customerCode?: string | null,
        // 得意先ユーザーの所属企業
        customer?:  {
          __typename: "Customer",
          code: string,
          name: string,
          salesOfficeCode?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        // 小川営業所コード（外部キー）
        salesOfficeCode?: string | null,
        // 小川従業員の所属営業所
        salesOffice?:  {
          __typename: "SalesOffice",
          code: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        // 管理者フラグ
        isAdmin?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSalesOfficeSubscriptionVariables = {
  filter?: ModelSubscriptionSalesOfficeFilterInput | null,
};

export type OnDeleteSalesOfficeSubscription = {
  onDeleteSalesOffice?:  {
    __typename: "SalesOffice",
    code: string,
    name: string,
    customers?:  {
      __typename: "ModelCustomerConnection",
      items:  Array< {
        __typename: "Customer",
        code: string,
        name: string,
        salesOfficeCode?: string | null,
        salesOffice?:  {
          __typename: "SalesOffice",
          code: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        employee?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    employee?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        // CognitoのID
        id: string,
        // Cognitoのユーザー名
        name: string,
        // アプリの表示名
        displayName: string,
        // ロール（小川管理者・小川従業員・顧客）
        role: Role,
        // 得意先コード（外部キー）
        customerCode?: string | null,
        // 得意先ユーザーの所属企業
        customer?:  {
          __typename: "Customer",
          code: string,
          name: string,
          salesOfficeCode?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        // 小川営業所コード（外部キー）
        salesOfficeCode?: string | null,
        // 小川従業員の所属営業所
        salesOffice?:  {
          __typename: "SalesOffice",
          code: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        // 管理者フラグ
        isAdmin?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCustomerSubscriptionVariables = {
  filter?: ModelSubscriptionCustomerFilterInput | null,
};

export type OnCreateCustomerSubscription = {
  onCreateCustomer?:  {
    __typename: "Customer",
    code: string,
    name: string,
    salesOfficeCode?: string | null,
    salesOffice?:  {
      __typename: "SalesOffice",
      code: string,
      name: string,
      customers?:  {
        __typename: "ModelCustomerConnection",
        items:  Array< {
          __typename: "Customer",
          code: string,
          name: string,
          salesOfficeCode?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      employee?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          // CognitoのID
          id: string,
          // Cognitoのユーザー名
          name: string,
          // アプリの表示名
          displayName: string,
          // ロール（小川管理者・小川従業員・顧客）
          role: Role,
          // 得意先コード（外部キー）
          customerCode?: string | null,
          // 小川営業所コード（外部キー）
          salesOfficeCode?: string | null,
          // 管理者フラグ
          isAdmin?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    employee?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        // CognitoのID
        id: string,
        // Cognitoのユーザー名
        name: string,
        // アプリの表示名
        displayName: string,
        // ロール（小川管理者・小川従業員・顧客）
        role: Role,
        // 得意先コード（外部キー）
        customerCode?: string | null,
        // 得意先ユーザーの所属企業
        customer?:  {
          __typename: "Customer",
          code: string,
          name: string,
          salesOfficeCode?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        // 小川営業所コード（外部キー）
        salesOfficeCode?: string | null,
        // 小川従業員の所属営業所
        salesOffice?:  {
          __typename: "SalesOffice",
          code: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        // 管理者フラグ
        isAdmin?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCustomerSubscriptionVariables = {
  filter?: ModelSubscriptionCustomerFilterInput | null,
};

export type OnUpdateCustomerSubscription = {
  onUpdateCustomer?:  {
    __typename: "Customer",
    code: string,
    name: string,
    salesOfficeCode?: string | null,
    salesOffice?:  {
      __typename: "SalesOffice",
      code: string,
      name: string,
      customers?:  {
        __typename: "ModelCustomerConnection",
        items:  Array< {
          __typename: "Customer",
          code: string,
          name: string,
          salesOfficeCode?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      employee?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          // CognitoのID
          id: string,
          // Cognitoのユーザー名
          name: string,
          // アプリの表示名
          displayName: string,
          // ロール（小川管理者・小川従業員・顧客）
          role: Role,
          // 得意先コード（外部キー）
          customerCode?: string | null,
          // 小川営業所コード（外部キー）
          salesOfficeCode?: string | null,
          // 管理者フラグ
          isAdmin?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    employee?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        // CognitoのID
        id: string,
        // Cognitoのユーザー名
        name: string,
        // アプリの表示名
        displayName: string,
        // ロール（小川管理者・小川従業員・顧客）
        role: Role,
        // 得意先コード（外部キー）
        customerCode?: string | null,
        // 得意先ユーザーの所属企業
        customer?:  {
          __typename: "Customer",
          code: string,
          name: string,
          salesOfficeCode?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        // 小川営業所コード（外部キー）
        salesOfficeCode?: string | null,
        // 小川従業員の所属営業所
        salesOffice?:  {
          __typename: "SalesOffice",
          code: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        // 管理者フラグ
        isAdmin?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCustomerSubscriptionVariables = {
  filter?: ModelSubscriptionCustomerFilterInput | null,
};

export type OnDeleteCustomerSubscription = {
  onDeleteCustomer?:  {
    __typename: "Customer",
    code: string,
    name: string,
    salesOfficeCode?: string | null,
    salesOffice?:  {
      __typename: "SalesOffice",
      code: string,
      name: string,
      customers?:  {
        __typename: "ModelCustomerConnection",
        items:  Array< {
          __typename: "Customer",
          code: string,
          name: string,
          salesOfficeCode?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      employee?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          // CognitoのID
          id: string,
          // Cognitoのユーザー名
          name: string,
          // アプリの表示名
          displayName: string,
          // ロール（小川管理者・小川従業員・顧客）
          role: Role,
          // 得意先コード（外部キー）
          customerCode?: string | null,
          // 小川営業所コード（外部キー）
          salesOfficeCode?: string | null,
          // 管理者フラグ
          isAdmin?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    employee?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        // CognitoのID
        id: string,
        // Cognitoのユーザー名
        name: string,
        // アプリの表示名
        displayName: string,
        // ロール（小川管理者・小川従業員・顧客）
        role: Role,
        // 得意先コード（外部キー）
        customerCode?: string | null,
        // 得意先ユーザーの所属企業
        customer?:  {
          __typename: "Customer",
          code: string,
          name: string,
          salesOfficeCode?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        // 小川営業所コード（外部キー）
        salesOfficeCode?: string | null,
        // 小川従業員の所属営業所
        salesOffice?:  {
          __typename: "SalesOffice",
          code: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        // 管理者フラグ
        isAdmin?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};
