import type { ButtonProps } from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import type { ReactNode, VFC } from "react";

type Props = ButtonProps & {
  children: ReactNode;
};

export const BaseButton: VFC<Props> = (props) => {
  const { children, ...styles } = props;
  return (
    <Button bg="white" _focus={{ outline: "none" }} {...styles}>
      {children}
    </Button>
  );
};
