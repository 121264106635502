import type { UseToastOptions } from "@chakra-ui/react";
import { useToast as _useToast } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { LinkText } from "src/components/ui/text/LinkText";

type Options = Omit<UseToastOptions, "status">;

export const useToast = () => {
  const _toast = _useToast();
  const router = useRouter();

  const error = useCallback(
    (options?: Options) => {
      const id = _toast({
        status: "error",
        position: "top",
        variant: "subtle",
        description: (
          <>
            システムエラーが発生しました。
            <br />
            時間をおいても解消されない場合は、担当者にお問合せください。
            <LinkText
              onClick={() => {
                _toast.close(id ?? "");
                router.push("/attention");
              }}
            >
              問合せ情報へ
            </LinkText>
          </>
        ),
        ...options,
      });
      return id;
    },
    [_toast]
  );

  const warning = useCallback(
    (options?: Options) => {
      return _toast({
        status: "warning",
        variant: "subtle",
        position: "top",
        ...options,
      });
    },
    [_toast]
  );

  const info = useCallback((options?: Options) => {
    return _toast({
      status: "info",
      variant: "subtle",
      position: "top",
      ...options,
    });
  }, []);
  return { toast: { ..._toast, warning, info, error } };
};
