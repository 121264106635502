import type { GraphQLError } from "graphql";
import type { dateRanges } from "src/constants";
import type { OrderInfo as _OrderInfo, ProductInfo } from "src/graphql/API";
import type { Day } from "src/libs/day";
import type { colors } from "src/utils/theme";

export type ColorKeys = keyof typeof colors;

export type Keyword = {
  place: string;
  orderedBy: string;
  productName: string;
};

export enum Status {
  ALL,
  COMPLETED,
  IN_PROGRESS,
}

export type DateRange = typeof dateRanges[number];

export type OrderInfo = {
  order: Omit<_OrderInfo, "products">;
  products: Omit<ProductInfo, "order">[];
};

export type SelectOption = { value: any; text: string };

export type OrderCondition = {
  place: string;
  productNo: string;
  productName: string;
  startDate: Day;
  endDate: Day;
  orderDateRange: DateRange;
  status: Status;
  customerCode: string;
};

export type GraphQLErrors = GraphQLError[] | null | undefined;

export type Response<T> = {
  data: T | null;
  errors: GraphQLErrors | readonly GraphQLError[];
};

export type FetchPolicy = "cache-first" | "cache-and-network" | "network-only" | "cache-only" | "no-cache" | "standby";
