import type { ConfigType, Dayjs } from "dayjs";
import dayjs from "dayjs";
import type { DateRange } from "src/types";

export type TemplateType = "YYYYMMDD" | "YYYY/MM/DD" | "YYYY/MM.DD" | "YY/MM.DD" | "YYYY/MM/DD HH:mm:ss";

export class Day {
  private day: Dayjs;

  constructor(date?: ConfigType | Day) {
    if (typeof date === "object") {
      this.day = dayjs(date?.toString());
      return;
    }
    this.day = dayjs(date);
  }

  public format(template?: TemplateType): string {
    return this.day.format(template);
  }

  public toFieldValue(): string {
    return this.day.format("YYYY-MM-DD");
  }

  public isValidStartDate(end: Day): boolean {
    return this.isSameDate(end) || this.day.isBefore(end.day);
  }

  public isValidEndDate(start: Day): boolean {
    return this.isSameDate(start) || this.day.isAfter(start.day);
  }

  private isSameDate(target: Day): boolean {
    return this.day.isSame(target.day, "date");
  }

  static convertDateRange(range: Exclude<DateRange, "ALL" | "MANUAL">): [Day, Day] {
    switch (range) {
      case "LAST_THREE_MONTHS":
        return [new Day(dayjs().subtract(3, "month")), new Day()];
      case "LAST_SIX_MONTHS":
        return [new Day(dayjs().subtract(6, "month")), new Day()];
    }
  }

  static dateRangeDisplay(start: Day, end: Day, template: TemplateType = "YYYY/MM/DD"): string {
    return `${start.format(template)} ~ ${end.format(template)}`;
  }
}
