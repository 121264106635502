import { useCallback } from "react";
import { dateRangeText, statusValue } from "src/constants";
import { useMixpanel } from "src/hooks/useMixpanel";
import { Day } from "src/libs/day";
import type { OrderCondition, OrderInfo } from "src/types";
import { Status } from "src/types";

export const useMixpanelOrder = () => {
  const { mixpanel } = useMixpanel();

  const trackOrderSearchCondition = useCallback(
    (condition: OrderCondition) => {
      const props = {
        "Customer Code": condition.customerCode,
        Place: condition.place,
        "Product No": condition.productNo,
        "Product Name": condition.productName,
        Status:
          condition.status === Status.ALL ? "すべて" : condition.status === Status.COMPLETED ? "発送済" : "未発送",
        "Ordered At": dateRangeText[condition.orderDateRange],
      };

      if (condition.orderDateRange === "MANUAL") {
        props["Ordered At"] = `${condition.startDate.format("YYYY/MM/DD")}~${condition.endDate.format("YYYY/MM/DD")}`;
      }

      mixpanel.track("SEARCH_ORDERS_CONDITION", props);
    },
    [mixpanel]
  );

  const trackOrderSearchById = useCallback(
    (orderNo: string) => {
      const props = {
        "Order No": orderNo,
      };
      mixpanel.track("SEARCH_ORDER", props);
    },
    [mixpanel]
  );

  const trackOrderDetailView = useCallback(
    (orderNo: string) => {
      const props = {
        "Order No": orderNo,
      };
      mixpanel.track("ORDER_DETAIL_VIEW", props);
    },
    [mixpanel]
  );

  const trackOrderDetailProductListView = useCallback(
    (orderInfo: OrderInfo["order"], products: OrderInfo["products"]) => {
      const key = `${orderInfo.orderNo}#${new Day().format("YYYY/MM/DD HH:mm:ss")}`;
      products.map((item) => {
        const props = {
          Key: key,
          "Order No": orderInfo.orderNo,
          customerCode: orderInfo.customerCode,
          customerName: orderInfo.customerName,
          remark1: orderInfo.remark1,
          remark2: orderInfo.remark2,
          "Product Code": item.productCode,
          "Product Name": item.productName,
          Quantity: item.quantity,
          Amount: item.amount,
          "Unit Price": item.unitPrice,
          "List Price": item.listPrice,
          "Ordered At": new Day(item.orderedAt).format("YYYY/MM/DD"),
          "Arranged At": item.arrangedAt ? new Day(item.arrangedAt).format("YYYY/MM/DD") : "",
          "Delivery Date": item.deliveryDate ? new Day(item.deliveryDate).format("YYYY/MM/DD") : "",
          "Slip Date": item.slipDate ? new Day(item.slipDate).format("YYYY/MM/DD") : "",
          Status: statusValue[item.productStatus] ?? item.productStatus,
        };
        mixpanel.track("ORDER_DETAIL_PRODUCT_LIST", props);
      });
    },
    [mixpanel]
  );

  const trackOrderDetailShare = useCallback(
    (orderNo: string) => {
      const props = {
        "Order No": orderNo,
      };
      mixpanel.track("ORDER_DETAIL_SHARE", props);
    },
    [mixpanel]
  );

  return {
    trackOrderSearchCondition,
    trackOrderSearchById,
    trackOrderDetailView,
    trackOrderDetailProductListView,
    trackOrderDetailShare,
  };
};
